import React from "react";
import {Link} from "react-router-dom";

export default function PromoCards3X(props) {
	return (
		<Link
			to={""}
			className="card w-100 b-radius b-shadow border-0 mt-2 p-4 mb-5 position-relative"
			data-aos="fade-up">
			<img
				className="position-absolute"
				src={props?.quote}
				style={{width: "120px", top: "-50px", right: "0%"}}
			/>
			<div className="row border-bottom pb-2">
				<div className="d-grid col-lg-3 text-uppercase text-lg-start text-center pb-3 pb-lg-0">
					<div className="fs-1 green-text fw-bolder">{props?.title}</div>
				</div>
				<div className="col-lg-9 d-flex align-items-center text-muted text-lg-start text-center fs-3 pb-3 pb-lg-0">
					{props?.subtitle}
				</div>
			</div>
			<div className="col-md-12 pt-4 text-muted">{props?.description}</div>
		</Link>
	);
}
