import React from 'react'
import logo from '../assets/images/logo_main.svg'

export default function LoaderMain() {
    return (
        <>
            <div id="preloader" className="preloader">
			<div className="arpinet">
				<div className="arp" id="arp_1"><div className="arper"></div></div>
				<div className="arp" id="arp_2"><div className="arper"></div></div>
				<div className="arp" id="arp_3"><div className="arper"></div></div>
				<div className="arp" id="arp_4"><div className="arper"></div></div>
				<div className="arp" id="arp_5"><div className="arper"></div></div>
				<div className="cssload-jumping">
					<div>
						<img className="animation flasharpinet" src={logo} alt="Arpinet LLC"/>
					</div>
					<div className="bubble-container">
						<span></span>
						<span></span>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
		</div>
        </>
    )
}
