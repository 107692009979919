import React, {useEffect} from 'react'
import $ from 'jquery'

export default function MetaTags(props) {
    useEffect(() => {
      $('head').find('title').empty().append(`«ԱՐՓԻՆԵՏ» ՍՊԸ - ` + props.title);
      $('head').append(`<meta property="fb:app_id" content=829517433891421 />`)
      $('head').find("og:image").text(`<meta property="og:image" content="`+ props.main_image +`" />`)
      $('head').append(`<meta property="og:site_name" content="«ԱՐՓԻՆԵՏ» ՍՊԸ - ` + props.title +`">`)
      $('head').append(`<meta property="og:type" content="article">`)
      $('head').append(`<meta property="og:title" content="`+ props.title +`">`)
      $('head').append(`<meta property="og:url" content="`+ props.url +`">`)
      $('head').append(`<meta property="og:description" content="`+ props.description +`"> `)
      $('head').append(`<link href=` + props.link + ` rel="stylesheet" type="text/css"/>`)
    }, [])
    return('')
}
