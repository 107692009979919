import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASE_URL, freeFormUrl} from "../static/api";
import {FiArrowRight, FiArrowLeft, FiCheck, FiX} from "react-icons/fi";
import {isValidPhoneNumber} from "react-phone-number-input/min";
import InputMask from "react-input-mask";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";
import {NUMREGX} from "../static/Static";
import {Link} from "react-router-dom";

export default function AdditionalRequsetForm() {
	const [state, setState] = useState({
		full_name: "",
		email: "",
		phone: "",
		job: "",
	});
	const [error, setError] = useState({
		full_name: null,
		email: null,
		phone: null,
	});

	const [step, setStep] = useState({
		one: true,
		two: false,
	});

	useEffect(() => {
		verify();
	}, [state]);

	function verify(e) {
		if ((state.full_name.length > 6) & state.full_name.includes(" ")) {
			setError({...error, full_name: true});
		}
		{
			setError({...error, full_name: false});
		}
	}

	function send(e) {
		e.preventDefault(e);

		if (state.full_name && state.email && state.phone && state.job != null) {
			axios({
				method: "POST",
				url: BASE_URL + freeFormUrl,
				data: {
					message:
						"Name: " +
						state.full_name +
						", Phone: " +
						state.phone +
						", email: " +
						state.email +
						", Job: " +
						state.job,
				},
			})
				.then(res => {
					if (res.status === 201) {
						setStep({one: false, two: true});
					}
				})
				.catch(error => {
					setStep({one: true, two: false});
				});
		} else {
			alert("error");
		}
	}

	return (
		<div className="col-12 py-5 my-5">
			<div className="container">
				<div className="card w-100 b-radius b-shadow p-5">
					{step.one ? (
						<>
							<div className="h3 text-center gradient-orange-bg gradient-text text-center text-uppercase pb-3">
								«Թվային վստահություն ԵՎ կիբեռանվտանգություն» դասընթաց
							</div>
							<div className="text-start white-space text">
								<p>
									«Արփինետ» ՍՊ ընկերության և ՀՀ պետական կառավարման ակադեմիայի նախաձեռնությամբ
									հունիսի 13-ից կմեկնարկի «Թվային վստահություն և կիբեռանվտանգություն» խորագրով
									դասընթացը։
								</p>
								<p>
									Դասընթացի նպատակն է բարձրացնել հասարակության վստահությունը թվային լուծումների
									նկատմամբ, ընդլայնել դրանց գիտակից օգտագործումը և ավելի արդյունավետ դարձնել
									կիբեռհանցագործությունների դեմ պայքարը։ Հատուկ ուշադրություն կդարձվի անձնական
									տվյալների պաշտպանության մասին հանրության լայն իրազեկմանը։Դասընթացի թեմաներն են՝
								</p>
								<p>1. Թվային գրագիտության կարևորությունն արդի աշխարհում</p>
								<p>2. Ժամանակակից տեխնոլոգիաների դերակատարությունը պատերազմական իրավիճակներում</p>
								<p>3. Ժամանակակից տեխնոլոգիաների կիրառմամբ առցանց հափշտակություններ</p>
								<p>4. Մետավերս և բլոկջեյն տեխնոլոգիաների կիրառմամբ հանցագործություններ</p>
								<p>5. Անձնական տվյալների պաշտպանությունը Հայաստանում</p>
								<p>
									6. Անձնական տվյալների պաշտպանությունը Մարդու իրավունքների եվրոպական դատարանի
									(ՄԻԵԴ) նախադեպային իրավունքի համատեքստում
								</p>
								<p>7. Կիբեռհիգիենա</p>
								<p>
									Դասերը կանցկացվեն ժամը 18։30-21:20՝ ՀՀ պետական կառավարման ակադեմիայում։ Դասընթացը
									կազմակերպվում է վճարովի հիմունքներով, մասնակցության վճարը՝ 55 000 ՀՀ դրամ:
									Մասնակցության համար անհրաժեշտ է լրացնել առցանց դիմում-հայտը։
								</p>

								<p>
									Լրացուցիչ տեղեկությունների համար կարող եք դիմել ՀՀ պետական կառավարման ակադեմիա՝
									զանգահարելով
									<a href="tel:+37410228934" className="mx-2">
										<i class="fa fa-phone mr-2"></i>010 22-89-34
									</a>{" "}
									հեռախոսահամարով:
								</p>
							</div>
							<form
								className="col-lg-5 col-md-7 col-sm-10 col-12 mx-auto py-5"
								onSubmit={e => send(e)}
							>
								<div className="mb-3">
									<input
										type="text"
										className="form-control mb-4 b-radius b-shadow px-4 py-3"
										placeholder="Անուն Ազգանուն"
										id="formFullName"
										required
										value={state.full_name}
										onBlur={e => verify(e)}
										onChange={e => {
											setState({...state, full_name: e.target.value});
										}}
									/>
								</div>

								<div className="mb-3">
									<InputMask
										type="tel"
										className={"form-control mb-4 b-radius b-shadow px-4 py-3"}
										id="formPhone"
										required
										value={state.phone}
										onChange={e => {
											setState({...state, phone: e.target.value.replace(NUMREGX, "")});
										}}
										mask="(999) 99-99-99"
										placeholder="(099) 99-99-99"
									/>
								</div>
								<div className="mb-3">
									<MaskedInput
										type="text"
										className={"form-control mb-4 b-radius b-shadow px-4 py-3"}
										placeholder="Էլ․ հասցե"
										id="formEmail"
										required
										value={state.email}
										mask={emailMask}
										onChange={e => setState({...state, email: e.target.value})}
									/>
								</div>
								<div className="mb-3 mt-5">
									<label htmlFor="formJob" className="form-label small fst-italic">
										Եթե այժմ աշխատում եք ապա խնդրում ենք նշել աշխատանքի վայրը. Օրինակ՝ «Արփինետ» ՍՊԸ{" "}
										<br /> <span className="text-danger">(պարտադիր դաշտ չէ)</span>
									</label>
									<input
										type="text"
										className="form-control mb-4 b-radius b-shadow px-4 py-3"
										id="formJob"
										value={state.job}
										onChange={e => setState({...state, job: e.target.value})}
									/>
								</div>
							</form>
							<div className="col-sm-12 mx-auto mt-4 mb-2 text-center">
								<button
									type="submit"
									className="net-btn green-bg green-shadow py-3 px-4 m-2"
									onClick={e => send(e)}
									disabled={state.full_name && state.phone && state.email ? null : "disabled"}
								>
									Հաստատել <FiArrowRight className="ms-2" />
								</button>
							</div>
						</>
					) : null}

					{step.two ? (
						<div className="d-flex justify-content-center align-items-center my-5">
							<div className="text-center">
								<p className="green-text text-uppercase h4">Շնորհակալություն</p>
								<hr />
								<p className="text-muted mb-5">Շուտով մեր մասնագետները կապ կհաստատեն Ձեզ հետ:</p>
								<Link to={"/"} className="net-btn green-bg green-shadow py-2 px-5 m-2">
									Վերադառնալ գլխավոր էջ
								</Link>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}
