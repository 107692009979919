export const NAVIGATIONBARITEMS = [
    {
        id: '01',
        link: '/internet',
        title: 'nav_internet',
    },
    {
        id: '02',
        link: '/tv',
        title: 'nav_iptv',
    },
    {
        id: '03',
        link: '/mobile-tv',
        title: 'nav_mobile_tv',
    },
    {
        id: '04',
        link: '/arpicam',
        title: 'nav_arpicam',
    },
    {
        id: '05',
        link: '/phone',
        title: 'nav_voip',
    },
]

export const CERVICESENTERS = [
    {
        id: 1,
        city: '_footer_addr_0',
        latitude: '40.208580000000000',
        longitude: '44.530866000000000',
        fullmap: 'https://yandex.ru/maps/-/CCUGnKfb0C'
    },
    {
        id: 2,
        city: '_footer_addr_1',
        latitude: '40.201624000000000',
        longitude: '44.489648000000000',
        fullmap: 'https://yandex.ru/maps/-/CCQpbEc40B'
    },
    {
        id: 3,
        city: '_footer_addr_2',
        latitude: '40.168991000000000',
        longitude: '44.291017000000000',
        fullmap: 'https://yandex.ru/maps/-/CCUbzGcUHD'
    },
    {
        id: 4,
        city: '_footer_addr_3',
        latitude: '40.152750000000000',
        longitude: '44.036642000000000',
        fullmap: 'https://yandex.ru/maps/-/CCQpbEc40B'
    },
    {
        id: 5,
        city: '_footer_addr_4',
        latitude: '40.617023000000000',
        longitude: '43.970461000000000',
        fullmap: 'https://yandex.ru/maps/-/CCQpbEc40B'
    },
    {
        id: 6,
        city: '_footer_addr_5',
        latitude: '40.144624000000000',
        longitude: '44.116135000000000',
        fullmap: 'https://yandex.ru/maps/-/CCQpbEc40B'
    },
    {
        id: 7,
        city: '_footer_addr_6',
        latitude: '40.383749000000000',
        longitude: '43.864779000000000',
        fullmap: 'https://yandex.ru/maps/-/CCQpbEc40B'
    },
    {
        id: 8,
        city: '_footer_addr_7',
        latitude: '39.854078000000000',
        longitude: '44.696627000000000',
        fullmap: 'https://yandex.ru/maps/-/CCQpbEc40B'
    },
    {
        id: 9,
        city: '_footer_addr_8',
        latitude: '39.912829000000000',
        longitude: '44.720529000000000',
        fullmap: 'https://yandex.ru/maps/-/CDUrq81I'
    },
    {
        id: 11,
        city: '_footer_addr_9',
        latitude: '39.954379',
        longitude: '44.548410',
        fullmap: 'https://yandex.ru/maps/-/CDUrqGlp'
    }
]

