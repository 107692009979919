import React from 'react'
import ApplicationRequest from '../components/ApplicationRequest'
import { FaInternetExplorer } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function TariffInternetComponent(props) {
    const { t } = useTranslation();
    return (
        <>
            {props.data.map((tarif) =>
                <div className="col-lg-3 col-md-4 col-sm-6 col-10 p-2" key={tarif.id}>
                    <div className="card b-radius b-shadow border-0 internet m-2 text-center">
                        <h4 className="h4 orange-text fw-bolder text-uppercase">{t('nav_internet')}</h4>
                        <h3 className='h2 fw-bolder green-text'>{tarif.price}</h3>
                        <div className="inet-info col-12 pt-0 d-flex align-items-center">
                            <FaInternetExplorer className="h2 orange-text m-0 me-3" />
                            <h1 className="">{tarif.speed}</h1>
                            <ul className="till-wrapper pt-3">
                                <li>{t('_tariff_till')} </li>
                                <li>{t('_tariff_speed')}</li>
                            </ul>
                        </div>
                        <div className="col-sm-12 p-0 mt-4 text-center">
                            <ApplicationRequest data={tarif} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
