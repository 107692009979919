import React, {useEffect, useState, lazy, Suspense} from "react";
import SpeedCheckComponent from "./components/SpeedCheckComponent";
import TariffInternetComponent from "./components/TariffInternetComponent";
import axios from "axios";
import {BASE_URL, tariffsURL, locationsURL} from "../static/api";
import {FaExclamationTriangle, FaExclamationCircle, FaMapMarker} from "react-icons/fa";
import MetaTags from "./ui-elements/MetaTags";

import {useTranslation} from "react-i18next";

import hy_lg_internet from "../assets/images/slider/hy_lg_internet.jpg";
import en_lg_internet from "../assets/images/slider/en_lg_internet.jpg";
import ru_lg_internet from "../assets/images/slider/ru_lg_internet.jpg";
import hy_sm_internet from "../assets/images/slider/hy_sm_internet.jpg";
import ru_sm_internet from "../assets/images/slider/ru_sm_internet.jpg";
import en_sm_internet from "../assets/images/slider/en_sm_internet.jpg";

const InternetUrl = axios.get(BASE_URL + tariffsURL + `?iptv=false&catv=false`);
const locations = axios.get(BASE_URL + locationsURL);

export default function InternetPage() {
	const lng = localStorage.getItem("i18nextLng");
	const {t} = useTranslation();
	const [state, setState] = useState({
		load: null,
		internet: [],
		locationData: [],
	});

	useEffect(() => {
		axios.all([InternetUrl, locations]).then(
			axios.spread((...responses) => {
				setState({
					internet: responses[0].data,
					locationData: responses[1].data,
					load: true,
				});
			})
		);
	}, [setState]);

	const MapIPTV = lazy(() => {
		return new Promise(resolve => {
			setTimeout(() => resolve(import("./components/MapPoints.jsx")), 1500);
		});
	});
	return (
		<>
			<MetaTags title={"Օպտիկամանրաթելային կապ"} />
			<div className="col-12 p-0">
				<div className="container pb-0">
					<img
						className="hidden-sm hidden-xs"
						alt={"Arpinet LLC"}
						src={
							lng !== "en-US" ? (lng == "ru-RU" ? ru_lg_internet : hy_lg_internet) : en_lg_internet
						}
					/>
					<img
						className="hidden-lg hidden-md"
						alt={"Arpinet LLC"}
						src={
							lng !== "en-US" ? (lng == "ru-RU" ? ru_sm_internet : hy_sm_internet) : en_sm_internet
						}
					/>
				</div>
				<div className="sep-line mt-2"></div>
			</div>

			<div className="col-12 pt-5 p-0 pb-5 pricing">
				<div className="container pb-5">
					<div className="h3 text-center gradient-orange-bg gradient-text pb-2">
						{t("internet_page_headline")}
					</div>
					<p className="text text-justify pt-2 pb-2">{t("internet_page_subText")}</p>
					<div className="row pt-2 justify-content-center pb-5">
						{state.load == true ? <TariffInternetComponent data={state.internet} /> : null}
					</div>
					<p className="text-muted">
						<FaExclamationTriangle className="orange-text me-2" />
						{t("internet_page_info_text")}
						<span className="mx-1 orange-text"> {t("internet_page_info_text_price")} </span>
						{t("internet_page_info_text_amd")}
					</p>
				</div>
				<div className="sep-line"></div>
			</div>

			<SpeedCheckComponent />

			<section className="col-12 p-0">
				<div className="container text pt-5 pb-5">
					<FaExclamationCircle className="orange-text me-2" /> {t("internet_page_informer")}
				</div>
				<div className="sep-line"></div>
			</section>

			<div className="col-12 p-0 pt-5 pb-1">
				<div className="container">
					<h3 className="gradient-orange-bg gradient-text pb-2 text-center text-uppercase">
						{t("_availability_map")}
					</h3>
					{state.load == true ? <MapIPTV data={state.locationData} /> : null}
					<div className="col-12 pl-0 pr-0 pt-5 pb-5 ">
						<div className="text text-justify">
							<FaMapMarker className="green-text me-2" />
							{t("_availability_green")}
						</div>
						<div className="text text-justify">
							<span className="orange-text me-2">*</span> {t("_availability_attantion")}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
