import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {BASE_URL, sliderURL} from "../../static/api";
import PaymentMethods from "../components/PaymentMethods";
import {Toast} from "primereact/toast";
import Loader from "../Loader";
import ErrorPage from "../layout_components/ErrorPage";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import {Routes, Route} from "react-router-dom";

import UserSideBar from "./UserSideBar";
import FeedBack from "./FeedBack";
import UserPaymentHistory from "./UserPaymentHistory";
import UserAgreements from "./UserAgreements";

export default function UserPageRouter() {
	const {t} = useTranslation();
	const toast = useRef(null);
	const [state, setState] = useState();
	const [slide, setSlide] = useState();
	const [error, setError] = useState();

	useEffect(() => {
		loadData();
	}, [setState, setSlide]);

	function loadData() {
		axios.get(`https://api.arpinet.am/api/user/data/`).then(res => {
			setState(res?.data.data);
			setError(res?.data?.error);
		});
		axios.get(BASE_URL + sliderURL).then(res => {
			setSlide(res?.data[5]);
		});
	}
	return state ? (
		<>
			{slide ? (
				<div className="col-12 p-0">
					<div className="container pb-0">
						<img src={slide.image} className="hidden-sm hidden-xs" alt={slide.title} />
						<img src={slide.thumb} className="hidden-lg hidden-md" alt={slide.title} />
					</div>
					<div className="sep-line mt-2"></div>
				</div>
			) : null}
			<div className="col-sm-12 p-0 pt-5 pb-5">
				<div className="container">
					<div className="row">
						<div className="col-xl-3 col-lg-4 col-md-5 col-12 position-md-sticky pe-lg-4">
							<div className="col-12 stretch-card mb-3 mt-3 p-0 position-md-sticky">
								<UserSideBar
									user={state?.abonent_name + " " + state?.abonent_surname}
									uid={state?.uid}
									agreement={state?.agreements?.length}
									mobile={state?.mobile}
									email={state?.email}
									t={t}
								/>
							</div>
						</div>

						<div className="col-xl-9 col-lg-8 col-md-7 col-12 mt-3 ">
							<Routes>
								<Route index path="dashboard" element={<UserAgreements t={t} data={state} />} />
								<Route
									exact
									path="payment_history/"
									element={<UserPaymentHistory data={state} />}
								/>
								<Route
									exact
									path="feedback"
									element={
										<FeedBack t={t} user={state?.abonent_name + " " + state?.abonent_surname} />
									}
								/>
								<Route exact path="/*" element={<ErrorPage />} />
							</Routes>
						</div>
					</div>
					<PaymentMethods />
				</div>
			</div>

			<Toast className="mt-5 py-2" ref={toast} />
		</>
	) : (
		<Loader />
	);
}
