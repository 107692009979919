export const REGX = /[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
export const NUMREGX = /[A-z`~!@#$%^&*()_|\-=?;:'' '",.<>s]/gi;
export const ONLYNUMBERSREGX = /\D/;

export const alt = 'Arpinet am';
export const alt_main = 'Arpinet LLC';
export const alt_arpicam = 'Arpinet LLC - Arpicam Service';
export const alt_mobile_tv = 'Arpinet TV mobile application';
export const alt_payments = 'Arpinet payment methods';



