import { FiPhone, FiMail, FiUser } from 'react-icons/fi';
import ServiceCenters from '../components/ServiceCenters'
import CardIcons from '../ui-elements/CardIcons'

export default function ContactPage(props) {
    return (
        <div className="col-12 pt-5 p-0 pb-5">
            <div className="container pb-5">
                <div className="card b-radius b-shadow article mt-5 pt-4">
                    <ServiceCenters />
                    <div className="col-12 p-0 pb-5">
                        <div className="container pb-5">
                            <div className="row">
                                <CardIcons external={'tel:+37460888888'} link={'#'} headline={'060 888 888'} subhead={props.t('_contacts_call_center')} icon={<FiPhone />} />
                                <CardIcons external={'mailto:info@arpinet.am'} link={'#'} headline={'info@arpinet.am'} subhead={props.t('_contacts_info_mail')} icon={<FiMail />} />
                                <CardIcons external={'mailto:hr@arpinet.am'} link={'#'} headline={'hr@arpinet.am'} subhead={props.t('_contacts_cv_mail')} icon={<FiUser />} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
