import arpicam_01 from '../assets/images/arpicam/arpicam_01.png'
import arpicam_02 from '../assets/images/arpicam/arpicam_02.png'
import arpicam_03 from '../assets/images/arpicam/arpicam_03.png'
import arpicam_04 from '../assets/images/arpicam/arpicam_04.png'
import arpicam_05 from '../assets/images/arpicam/arpicam_05.png'


import day_05 from '../assets/images/arpicam/5days.png'
import day_15 from '../assets/images/arpicam/15days.png'
import day_30 from '../assets/images/arpicam/30days.png'

export const voipIncludes = [
    {
        id: "01",
        desc: "_voip_tariff_01",
        price: "4.5",
        currency: "_currency"
    },
    {
        id: "02",
        desc: "_voip_tariff_02",
        price: "17",
        currency: "_currency"
    },
    // {
    //     id: "03",
    //     desc: "_voip_tariff_03",
    //     price: "19",
    //     currency: "_currency"
    // },
    // {
    //     id: "04",
    //     desc: "_voip_tariff_04",
    //     price: "27",
    //     currency: "_currency"
    // },
    {
        id: "05",
        desc: "_voip_tariff_05",
        price: "0",
        currency: "_currency"
    },
]
export const voipExtra = [
    {
        id: "01",
        desc: "_voip_tariff_06",
        price: "3000",
        currency: "_currency"
    },
    {
        id: "02",
        desc: "_voip_tariff_07",
        price: "1000",
        currency: "_currency"
    },
    {
        id: "03",
        desc: "_voip_tariff_08",
        price: "3000",
        currency: "_currency"
    },
    {
        id: "04",
        desc: "_voip_tariff_09",
        price: "500",
        currency: "_currency"
    },
]
export const voipTarif = [
    {
        id: "1",
        name: "VoIP",
        subname: "_voip_tarif_title_1",
        price: "600",
        include: "180",
    },
    {
        id: "2",
        name: "VoIP",
        subname: "_voip_tarif_title_1",
        price: "1000",
        include: "360",
    },
    {
        id: "3",
        name: "VoIP",
        subname: "_voip_tarif_title_2",
        price: "2400",
        include: "0",
    },
]

export const arpicamDescription = [
    {
        id: '01',
        title: "_arpicam_reason_1_title",
        desc: "_arpicam_reason_1_description",
        img: arpicam_04
    },
    {
        id: '02',
        title: "_arpicam_reason_2_title",
        desc: "_arpicam_reason_2_description",
        img: arpicam_01
    },
    {
        id: '03',
        title: "_arpicam_reason_3_title",
        desc: "_arpicam_reason_3_description",
        img: arpicam_03
    },
    {
        id: '04',
        title: "_arpicam_reason_4_title",
        desc: "_arpicam_reason_4_description",
        img: arpicam_02
    },
    {
        id: '05',
        title: "_arpicam_reason_5_title",
        desc: "_arpicam_reason_5_description",
        img: arpicam_05
    },
]

export const arpicamPrice = [
    {
        id: '01',
        title: "_arpicam_tarif_headline",
        img: day_05,
        year_1_price: "3900",
        year_1_desc: "_arpicam_tarif_one_year",
        year_2_price: "2900",
        year_2_desc: "_arpicam_tarif_two_year",
    },
    {
        id: '02',
        title: "_arpicam_tarif_headline",
        img: day_15,
        year_1_price: "5900",
        year_1_desc: "_arpicam_tarif_one_year",
        year_2_price: "4900",
        year_2_desc: "_arpicam_tarif_two_year",
    },
    {
        id: '03',
        title: "_arpicam_tarif_headline",
        img: day_30,
        year_1_price: "8900",
        year_1_desc: "_arpicam_tarif_one_year",
        year_2_price: "7900",
        year_2_desc: "_arpicam_tarif_two_year",
    },
]