import React from "react";
import { FiActivity } from "react-icons/fi";

export default function ErrorMessage() {
  return(
    <div  className="row my-5">
        <div className="card border-0 b-radius b-shadow p-5">
            <div className="col-12 text-center py-5">
                <div className='display-1 text-danger'><FiActivity/></div>
                <div className='col-sm-12 text-muted mt-4'>We are experiencing network difficulties at the moment. Please be patient and try again later.</div>
            </div>
        </div>
    </div>
    );
}
