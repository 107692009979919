import {alt_mobile_tv} from "../../static/Static";
import American_Express_logo from "../../assets/images/mobile-tv/credit_cards/American_Express_logo.png";
import arca_logo from "../../assets/images/mobile-tv/credit_cards/arca_logo.png";
import mastercard_logo from "../../assets/images/mobile-tv/credit_cards/mastercard_logo.png";
import visa_logo from "../../assets/images/mobile-tv/credit_cards/visa_logo.png";
import ImageCard from "./ImageCard";
export default function PaymentOptions(props) {
	return (
		<div className="col-sm-12 col-xs-12 text-muted pb-3 mb-3">
			<h4 className="w-100 text-center pt-3 pb-5">{props.t("_tariff_payment_types")}</h4>
			<div className="row col-md-10 mx-auto text-center justify-content-center">
				<ImageCard image={arca_logo} alt={alt_mobile_tv} />
				<ImageCard image={mastercard_logo} alt={alt_mobile_tv} />
				<ImageCard image={American_Express_logo} alt={alt_mobile_tv} />
				<ImageCard image={visa_logo} alt={alt_mobile_tv} />
			</div>
		</div>
	);
}
