import React, {useEffect, useState} from "react";
import {BASE_URL, POSTS_URL, notifications} from "../static/api";
import axios from "axios";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import PostsComponent from "./components/PostsComponent";
import NotifyCard from "./ui-elements/NotifyCard";
import MetaTags from "./ui-elements/MetaTags";

import HomeArpicam from "./components/HomeArpicam";
import HomeSlider from "./components/HomeSlider";
import SpeedCheckComponent from "./components/SpeedCheckComponent";
import CardIcons from "./ui-elements/CardIcons";

import {
	FiMonitor,
	FiPhone,
	FiVideo,
	FiUsers,
	FiMail,
	FiCheckCircle,
	FiBookmark,
	FiAward,
	FiBell,
	FiWifi,
} from "react-icons/fi";

import Promo3X from "./promotions/3x_promo/Promo3X";
import Index3X from "./promotions/3x_promo/Index3X";

export default function HomeComponent() {
	const lng = localStorage.getItem("i18nextLng");
	const {t} = useTranslation();
	const [state, setState] = useState({
		posts: [],
		events: [],
		promos: [],
		notifications: [],
	});

	useEffect(() => {
		axios
			.all([
				axios.get(BASE_URL + POSTS_URL + `?category=0`, {
					headers: {"Accept-Language": lng.substring(0, 2)},
				}),
				axios.get(BASE_URL + POSTS_URL + `?category=1`, {
					headers: {"Accept-Language": lng.substring(0, 2)},
				}),
				axios.get(BASE_URL + POSTS_URL + `?category=2`, {
					headers: {"Accept-Language": lng.substring(0, 2)},
				}),
				axios.get(BASE_URL + notifications),
			])
			.then(
				axios.spread((...responses) => {
					setState({
						posts: responses[0].data.results,
						events: responses[1].data.results,
						promos: responses[2].data.results,
						notifications: responses[3].data.results,
					});
				})
			);
	}, [setState, localStorage.getItem("i18nextLng")]);
	const token = localStorage.getItem("token");
	return (
		<>
			<MetaTags title={"ՔԵԶ ՀԵՏ, ՔՈ ՏԱՆԸ"} />
			<HomeSlider />
			<div className="col-sm-12 p-0 pt-5 pb-5 ">
				<div className="container">
					<div className="row my-4">
						<CardIcons
							link={"/internet"}
							headline={t("nav_internet")}
							subhead={t("nav_internet_subline")}
							icon={<FiWifi />}
						/>
						<CardIcons
							link={"/tv"}
							headline={t("nav_iptv")}
							subhead={t("nav_iptv_subline")}
							icon={<FiMonitor />}
						/>
						<CardIcons
							link={"/phone"}
							headline={t("nav_voip")}
							subhead={t("nav_voip_subline")}
							icon={<FiPhone />}
						/>
						<CardIcons
							link={"/arpicam"}
							headline={t("nav_arpicam")}
							subhead={t("nav_arpicam_subline")}
							icon={<FiVideo />}
						/>
						<CardIcons
							link={token ? `/user_account/dashboard` : `/login`}
							headline={t("nav_userlink")}
							subhead={t("nav_userlink_subline")}
							icon={<FiUsers />}
						/>
						<CardIcons
							link={"/contacts"}
							headline={t("nav_contacts")}
							subhead={t("nav_contacts_subline")}
							icon={<FiMail />}
						/>
					</div>
				</div>
			</div>
			<div className="sep-line"></div>
			<Index3X />
			<div className="sep-line"></div>
			<HomeArpicam />
			<SpeedCheckComponent />
			<div className="col-xs-12 p-0 pt-5 pb-5">
				<div className="container pt-5 pb-5">
					<ul className="nav nav-pills justify-content-center mb-3" id="pills-tab" role="tablist">
						<li className="nav-item">
							<Link
								className="net-btn green-bg green-shadow py-md-3 px-md-4 py-sm-2 px-sm-2  m-2 active"
								id="tab-news-tab"
								data-bs-toggle="pill"
								to="#tab-news"
								role="tab"
							>
								<FiCheckCircle className="me-2" />
								{t("_news")}
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className="net-btn green-bg green-shadow py-md-3 px-md-4 py-sm-2 px-sm-2  m-2"
								id="tab-events-tab"
								data-bs-toggle="pill"
								to="#tab-events"
								role="tab"
							>
								<FiAward className="me-2" />
								{t("_events")}
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className="net-btn green-bg green-shadow py-md-3 px-md-4 py-sm-2 px-sm-2  m-2"
								id="tab-promo-tab"
								data-bs-toggle="pill"
								to="#tab-promo"
								role="tab"
							>
								<FiBookmark className="me-2" />
								{t("_offers")}
							</Link>
						</li>
						<li className="nav-item">
							<Link
								className="net-btn green-bg green-shadow py-md-3 px-md-4 py-sm-2 px-sm-2  m-2"
								id="tab-notifications-tab"
								data-bs-toggle="pill"
								to="#tab-notifications"
								role="tab"
							>
								<FiBell className="me-2" /> {t("_notifications")}
							</Link>
						</li>
					</ul>
					<div className="tab-content mt-5" id="pills-tabContent">
						<div className="tab-pane fade show active" id="tab-news" role="tabpanel">
							<PostsComponent posts={state.posts} />
						</div>
						<div className="tab-pane fade" id="tab-events" role="tabpanel">
							<PostsComponent posts={state.events} />
						</div>
						<div className="tab-pane fade" id="tab-promo" role="tabpanel">
							<PostsComponent posts={state.promos} />
						</div>
						<div className="tab-pane fade" id="tab-notifications" role="tabpanel">
							{state?.notifications?.slice(0, 5).map((notifications, i) => (
								<NotifyCard
									key={notifications.id}
									id={notifications.id}
									message={notifications.message}
									created={notifications.created}
									status={notifications.status}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
