import React, { useEffect } from 'react';

export default function UserLogout() {
    useEffect(() => {
        localStorage.removeItem("token");
        window.location.replace("/")
    }, [])
    return (
        <>
        
        </>
    )
}
