import { Link } from 'react-router-dom';
import {FiChevronRight} from 'react-icons/fi';

export default function FooterLinks(props) {
  return(

    <div className="col-xl-4 col-md-6 col-10 mx-auto my-3 my-md-0 pt-3 p-2 pr-4 links text-uppercase">
        <ul className="col-lg-10 col-sm-11">
            <li className="d-block"><Link className="pt-0 pb-2 col-12" to="/news"><FiChevronRight className="me-2" />{props.t("_footer_links_news")}</Link></li>
            <li className="d-block"><Link className="pt-3 pb-2 col-12" to="/notifications"><FiChevronRight className="me-2" />{props.t('_footer_links_notify')}</Link></li>
            <li className="d-block"><Link className="pt-3 pb-2 col-12" to="/career"><FiChevronRight className="me-2" />{props.t('_footer_links_job')}</Link></li>
            <li className="d-block"><Link className="pt-3 pb-2 col-12" to="/online-cv"><FiChevronRight className="me-2" />{props.t("_footer_links_online_cv")}</Link></li>
            <li className="d-block"><Link className="pt-3 pb-2 col-12" to="/contacts"><FiChevronRight className="me-2" />{props.t("_footer_links_contacts")}</Link></li>
        </ul>
    </div>
    );
}
