import React, {useState, useEffect, useRef} from "react";
import {BASE_URL, callRequestURL, identify, identify_confirm} from "../static/api";
import axios from "axios";
import {isValidPhoneNumber} from "react-phone-number-input/mobile";
import InputMask from "react-input-mask";
import {FiX, FiArrowRight, FiPhoneIncoming} from "react-icons/fi";
import {FaPhoneAlt} from "react-icons/fa";
import {REGX, NUMREGX} from "../static/Static";
import {useTranslation} from "react-i18next";

export default function CallRequest(e) {
	const {t} = useTranslation();
	const intervalRef = useRef();
	const [form_identification, setForm_identification] = useState(true);
	const [formConfirm_identification, setFormConfirm_identification] = useState(false);
	const [formMessage, setFormMessage] = useState(false);
	const [name, setName] = useState("");
	const [mobile, setMobile] = useState("");
	const [userConfiramtion, setuserConfiramtion] = useState("*");
	const [error, setError] = useState("");

	function identifyRequest(e) {
		e.preventDefault();
		if (name && isValidPhoneNumber(mobile)) {
			axios({
				method: "POST",
				url: BASE_URL + identify,
				data: {mobile: "+" + mobile},
			})
				.then(res => {
					if (res.status === 200) {
						setForm_identification(false);
						setFormConfirm_identification(true);
						setError_timer(60);
					}
				})
				.catch(error => {
					// setError(error.response.data.error)
					setForm_identification(true);
					setFormConfirm_identification(false);
				});
		}
	}

	function SubmitCode(e) {
		e.preventDefault();
		axios({
			method: "POST",
			url: BASE_URL + identify_confirm,
			data: {mobile: mobile, code: userConfiramtion},
		})
			.then(res => {
				if (res.status === 200) {
					SubmitCallRequest();
					setFormConfirm_identification(false);
					setFormMessage(true);
				}
			})
			.catch(error => {
				setError(error.response.data.error_code);
				setFormConfirm_identification(true);
				setFormMessage(false);
			});
	}

	function SubmitCallRequest(e) {
		axios({
			method: "POST",
			url: BASE_URL + callRequestURL,
			data: {name: name, mobile: mobile},
		})
			.then(res => {
				if (res.status === 200) {
					setFormConfirm_identification(false);
					setFormMessage(true);
				}
			})
			.catch(error => {
				setFormConfirm_identification(true);
				setFormMessage(false);
			});
	}

	function ModalClose(e) {
		setTimeout(() => {
			setForm_identification(true);
			setFormConfirm_identification(false);
			setFormMessage(false);
			setName("");
			setMobile("");
			setuserConfiramtion("");
			setError("");
		}, 1500);
	}

	const [error_timer, setError_timer] = useState(60);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setError_timer(t => t - 1);
		}, 1000);
		return () => clearInterval(intervalRef.current);
	}, [error_timer]);

	useEffect(() => {
		if (error_timer <= 0) {
			clearInterval(intervalRef.current);
		}
	}, [error_timer]);

	return (
		<>
			<button data-bs-toggle="modal" data-bs-target="#call-request" className="pulse request-call">
				<FaPhoneAlt />
			</button>

			<div
				className="modal fade"
				id="call-request"
				data-bs-backdrop="static"
				tabIndex="-1"
				role="dialog"
			>
				<div className="modal-dialog modal-md modal-dialog-centered">
					<div className="modal-content bg-white">
						<button
							type="button"
							className="close border-0"
							data-bs-dismiss="modal"
							onClick={e => ModalClose(e)}
						>
							<FiX />
						</button>

						{form_identification ? (
							<div className="modal-body">
								<form onSubmit={e => identifyRequest(e)}>
									<h3 className="gradient-orange-bg gradient-text text-center col-12 pb-2 text-uppercase pt-2">
										{t("_call_req_header")}
									</h3>
									<div className="form-floating mb-4 mt-3 col-sm-12">
										<input
											type="text"
											className="form-control b-radius mt-2 b-shadow"
											name="last_name"
											id="last_name"
											placeholder={t("_name")}
											required
											autoFocus
											value={name}
											onChange={e => setName(e.target.value.replace(REGX, ""))}
										/>
										<label htmlFor="last_name">{t("_name")}</label>
									</div>

									<div className="form-floating mb-4 mt-3 col-sm-12">
										<InputMask
											type="tel"
											className="form-control b-radius mt-2 b-shadow"
											id="phone"
											autoFocus
											required
											mask="+374 (99) 99-99-99"
											placeholder="+374 (99) 99-99-99"
											value={mobile}
											onChange={e => setMobile(e.target.value.replace(NUMREGX, ""))}
										/>
										<label htmlFor="phone">{t("_mobile")}</label>
									</div>

									<div className="small text-danger">{error}</div>

									<div className="form-group col-12 text-center pt-4">
										<button
											type="button"
											className="net-btn orange-bg orange-shadow py-3 px-4 m-2"
											data-bs-dismiss="modal"
											onClick={e => ModalClose(e)}
										>
											{t("_cancel")}
											<FiX className="ms-2" />
										</button>
										<button
											type="submit"
											className="net-btn green-bg green-shadow py-3 px-4 m-2"
											onClick={e => identifyRequest(e)}
											disabled={name && isValidPhoneNumber(mobile) ? null : true}
										>
											{t("_request")}
											<FiArrowRight className="ms-2" />
										</button>
									</div>
								</form>
							</div>
						) : null}

						{formConfirm_identification ? (
							<div className="modal-body p-2">
								<form className="col-md-10 col-sm-12 mx-auto" onSubmit={e => SubmitCode(e)}>
									<div className="text-center mb-4">
										{t("_request_text_line_1")} 060XX<span className="text-danger">XXXX</span>{" "}
										{t("_request_text_line_2")}
										<div className="small mt-2 col-12">
											{error_timer > 0 ? (
												<>
													{t("_request_error_text")} {error_timer} {t("_request_error_seconds")}
												</>
											) : (
												<button
													onClick={e => {
														identifyRequest(e);
														setError_timer(60);
													}}
													className="btn green-text mt-2 p-0 m-0"
												>
													<FiPhoneIncoming className="me-2" /> {t("_request_repeat")}
												</button>
											)}
										</div>
										{error ? (
											<>
												<br />
												<span className="text-danger">{t("_request_error")}</span>
											</>
										) : null}
									</div>
									<div className="form-floating mb-5 mt-3">
										<InputMask
											type="tel"
											required
											autoFocus
											className="form-control text-center b-radius b-shadow mt-2 text-muted fw-bold ls-30 mb-3"
											mask="9999"
											placeholder="_ _ _ _"
											value={userConfiramtion}
											onChange={e => setuserConfiramtion(e.target.value.replace(NUMREGX, ""))}
										/>
									</div>

									<div className="col-sm-12 mx-auto mt-4 mb-2 text-center">
										<button
											type="submit"
											onClick={e => SubmitCode(e)}
											disabled={userConfiramtion.length == 4 ? null : true}
											className="net-btn green-bg green-shadow py-3 px-4 m-2"
										>
											{t("_confirm")} <FiArrowRight className="ms-2" />
										</button>
									</div>
								</form>
							</div>
						) : null}

						{formMessage ? (
							<div className="d-flex justify-content-center align-items-center">
								<div className="text-center">
									<p className="green-text text-uppercase h4 ">{t("_call_req_done_line_1")}</p>
									<hr />
									<p className="text-muted">{t("_call_req_done_line_2")}</p>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
}
