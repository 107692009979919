import { FiWifi } from 'react-icons/fi'

export default function ConnectionStatus(props) {
  return (
    <div className='text-center'>
      <FiWifi style={{ fontSize: '25vw', color: '#d2d2d2' }} />
      <div className='mt-4'>
        {props.t('_hmmm_')}<br /> {props.t('_disconnected_')}
      </div>
    </div>
  )
}

