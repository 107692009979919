import React from "react";
import {useTranslation} from "react-i18next";
import hy_lg_3x from "../../../assets/images/slider/hy_lg_3x.jpg";
import en_lg_3x from "../../../assets/images/slider/en_lg_3x.jpg";
import ru_lg_3x from "../../../assets/images/slider/ru_lg_3x.jpg";
import hy_sm_3x from "../../../assets/images/slider/hy_sm_3x.jpg";
import ru_sm_3x from "../../../assets/images/slider/ru_sm_3x.jpg";
import en_sm_3x from "../../../assets/images/slider/en_sm_3x.jpg";
import quote from "../../../assets/images/icons/quote.png";
import PromoCards3X from "../components/PromoCards3X";

import MetaTags from "../../ui-elements/MetaTags";

export default function Promo3X() {
	const lng = localStorage.getItem("i18nextLng");
	const {t} = useTranslation();
	return (
		<div className="col-xs-12 p-0 position-relative">
			<div className="container py-4 p-0">
				<img
					className="hidden-sm hidden-xs"
					alt={"Arpinet LLC"}
					src={
						lng !== "en-US" ? (lng == "ru-RU" ? ru_lg_3x : hy_lg_3x) : en_lg_3x
					}
				/>
				<img
					className="hidden-lg hidden-md"
					alt={"Arpinet LLC"}
					src={
						lng !== "en-US" ? (lng == "ru-RU" ? ru_sm_3x : hy_sm_3x) : en_sm_3x
					}
				/>
			</div>
			<div className="sep-line"></div>

			<div className="col-xs-12 p-0 pt-5 pb-2 pricing">
				<div className="container pt-3 pb-2">
					<div className="col-12 pt-2 pb-4">
						<div className="row"></div>

						<div className="col-12 pt-2 pb-4 p-0">
							<div className="row">
								<PromoCards3X
									quote={quote}
									title={t("__promotion_3x_title_text_1_")}
									subtitle={t("__promotion_3x_subtitle_text_1_")}
									description={t("__promotion_3x_description_text_1_")}
								/>
								<PromoCards3X
									quote={quote}
									title={t("__promotion_3x_title_text_2_")}
									subtitle={t("__promotion_3x_subtitle_text_2_")}
									description={t("__promotion_3x_description_text_2_")}
								/>
								<PromoCards3X
									quote={quote}
									title={t("__promotion_3x_title_text_3_")}
									subtitle={t("__promotion_3x_subtitle_text_3_")}
									description={t("__promotion_3x_description_text_3_")}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
