import React, {useState} from "react";
import {FiInfo, FiCalendar, FiCreditCard, FiBarChart2, FiMinus, FiPlus} from "react-icons/fi";
import {Link} from "react-router-dom";
import VgActions from "./VgActions";
import {Dialog} from "primereact/dialog";
import PaymentForm from "./PaymentForm";

export default function Agreements(props) {
	const [collapse, setCollapse] = useState(false);
	const [colId, setColId] = useState();
	const [visible, setVisible] = useState(false);

	function collapseCard(e) {
		if (colId == e) {
			setCollapse(true);
			setColId(e);
		} else {
			setCollapse(false);
			setColId(e);
		}
	}
	function setkey() {
		localStorage.setItem("key", props.agreements.agrm_id);
	}

	return (
		<div className="row mb-3">
			<div className="col-sm-12 p-0">
				<div className="card border-0 b-radius b-shadow px-3 py-2 mb-2">
					<div className="col-sm-12 d-inline-flex align-items-center justify-content-between collapsed">
						<a
							className="fs-4 text-muted mb-0 d-inline-flex align-items-center"
							data-bs-toggle="collapse"
							href={"#" + "collapse" + `${props?.agreements.agrm_id}`}
							aria-expanded="true"
							onClick={e => {
								collapseCard(props?.agreements.agrm_id);
								setCollapse(!collapse);
							}}
						>
							{collapse ? <FiPlus /> : <FiMinus />}

							<div
								className={
									props?.agreements.balance <= 1000
										? "ms-3 fs-5 mb-0 d-inline-flex align-items-center text-danger text-uppercase"
										: "ms-3 fs-5 mb-0 d-inline-flex align-items-center text-uppercase"
								}
							>
								<FiInfo className="me-3 d-md-block d-none" />
								<span className="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-9">
									{props?.t("_user_page_agreements_")} N {props?.agreements.agrm_num}
								</span>
							</div>
						</a>
						<div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-3 text-end">
							<button
								className="net-btn green-bg green-shadow py-lg-3 px-lg-4 m-lg-2 py-md-2 px-md-2 m-md-2 py-sm-2 px-sm-2 m-sm-2 py-1 px-0 m-1"
								onClick={e => setVisible(!visible)}
							>
								{props?.t("_user_page_online_payment_btn")}
							</button>
						</div>
					</div>
					<div
						className={
							collapse && colId === props?.agreements.agrm_id ? `collapse hide` : `collapse  show `
						}
						id={"collapse" + `${props?.agreements.agrm_id}`}
					>
						<hr className="mt-3 mb-4" />
						<div className="col-12">
							<div className="col-sm-12 text-muted mb-2">{props?.t("_user_page_information")}</div>
							<div className="row mt-4 mb-4">
								<div className="col-md-6 col-sm-12">
									<div className="col-sm-12 m-2 d-flex align-items-center text-muted">
										<span className="badge badge-success me-3">
											<FiCalendar />
										</span>
										{props?.t("_user_page_subscribtion_date")} {props?.agreements.create_date}
									</div>
									<Link
										to="/user_account/payment_history/"
										className="col-sm-12 m-2 d-flex align-items-center text-muted mt-3"
										onClick={e => setkey()}
									>
										<span className="badge badge-success me-3">
											<FiBarChart2 />
										</span>
										<span className="d-grid">
											<span className="lh-0 my-2">
												{props?.t("_user_page_online_payment_history_title")}
											</span>
											<span className="small fst-italic text-danger">
												{props?.t("_user_page_online_payment_history_subtitle")} N{" "}
												{props?.agreements.agrm_num}
											</span>
										</span>
									</Link>
								</div>
								<div className="col-md-6 col-sm-12 ">
									<Link to={""} className="col-sm-12 m-2 d-flex align-items-center">
										<span
											className={
												props?.agreements.balance <= 1000
													? "badge badge-danger me-3"
													: "badge badge-success me-3"
											}
										>
											<FiCreditCard />
										</span>
										<span
											className={props?.agreements.balance <= 1000 ? "text-danger" : "text-muted"}
										>
											{props?.t("_user_page_balance")} {parseInt(props?.agreements.balance, 10)}{" "}
											<span className="small">Դ․</span>
										</span>
									</Link>
								</div>
							</div>
							<hr className="mt-3 mb-4" />
							<div className="col-sm-12 text-muted mt-4">{props?.t("_user_page_services")}</div>
							<div className="row mt-4 mb-4">
								{props?.agreements.tariffs.map((tariff, index) => (
									<div key={index + tariff.name} className="col-sm-12 d-flex m-2">
										<span className="badge badge-warning me-3">
											<FiInfo />
										</span>
										<span className="fst-italic">
											{tariff.name} - {tariff.rent} Դ․
										</span>
									</div>
								))}
								{/* <div className="col-sm-12 text-muted mt-4">
									<FiAlertCircle className="me-2" />
									If you have any devices, they will be displayed here, and you can make changes, such as altering your device's password or adjusting the WiFi device range.
								</div> */}
								{/* {props?.agreements.tariffs.map((tariff, index) => (tariff.device.vg_id ? <VgActions key={index} devices={tariff.device} icon={<FiServer />} toast={props?.toast} loadData={props.loadData} /> : null))} */}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Dialog
				header={props?.t("_user_page_agreements_") + " N " + " " + props?.agreements.agrm_num}
				visible={visible}
				style={{width: "50vw"}}
				onHide={() => {
					if (!visible) return;
					setVisible(false);
				}}
			>
				<PaymentForm data={props} t={props?.t} />
			</Dialog>
		</div>
	);
}
