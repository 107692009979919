import {useState, useEffect} from "react";
import {Link, NavLink} from "react-router-dom";
import logo from "../../assets/images/logo_main.svg";
import {FiMenu, FiX} from "react-icons/fi";
import {NAVIGATIONBARITEMS} from "../../static/StaticJson";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import lng_hy from "../../assets/images/icons/armenia.png";
import lng_en from "../../assets/images/icons/united-states.png";
import lng_ru from "../../assets/images/icons/russia.png";
import {Button} from "primereact/button";

export default function NavBar() {
	const {t} = useTranslation();
	const lng = localStorage.getItem("i18nextLng");
	const token = localStorage.getItem("token");
	const [collapsing, setCollapsing] = useState(false);
	const [navbarVisible, setNavbarVisible] = useState(false);
	const [show, setShow] = useState(false);

	function handleClick(lang) {
		i18next.changeLanguage(lang);
	}

	function toggleNavBar() {
		setCollapsing(true);
		// setNavbarVisible(true);
		setTimeout(() => {
			setCollapsing(false);
			setNavbarVisible(true);
		}, 50);
	}
	return (
		<>
			<nav className="navbar navbar-expand-xl position-fixed top-0 bg-blur b-shadow">
				<div className="container">
					<Link to="/" className="logo text-center">
						<img src={logo} alt="Arpinet LLC" />
					</Link>
					<Button
						className="navbar-toggler nav-link"
						onClick={() => {
							toggleNavBar();
						}}
					>
						<FiMenu />
					</Button>
					<div
						className={`navbar-collapse justify-content-end ${
							collapsing ? "collapsing" : "collapse"
						} ${navbarVisible ? "show " : "hide"}`}
					>
						<Button
							className="navbar-toggler"
							onClick={() => {
								setNavbarVisible(false);
							}}
						>
							<FiX />
						</Button>
						<ul className="navbar-nav text-uppercase ">
							{NAVIGATIONBARITEMS.map((n, i) => (
								<li key={i} className="nav-item">
									<NavLink
										className="nav-link text-muted"
										to={n.link}
										onClick={() => setNavbarVisible(false)}
									>
										{t(`${n.title}`)}
									</NavLink>
								</li>
							))}

							<li className="nav-item">
								<NavLink
									className="nav-link text-muted"
									to={token ? "/user_account/dashboard" : "/login"}
									onClick={() => setNavbarVisible(false)}
								>
									{t("nav_userlink")}
								</NavLink>
							</li>

							{token && (
								<li className="nav-item">
									<NavLink
										className="nav-link text-muted"
										to="/logout"
										onClick={() => setNavbarVisible(false)}
									>
										{t("nav_logout")}
									</NavLink>
								</li>
							)}
							<li className="nav-item language-switcher">
								<button
									onClick={() => {
										handleClick("en-US");
										setNavbarVisible(false);
									}}
								>
									<img src={lng_en} alt="Arpinet" />
								</button>
								<button
									onClick={() => {
										handleClick("ru-RU");
										setNavbarVisible(false);
									}}
								>
									<img src={lng_ru} alt="Arpinet" />
								</button>
								<button
									onClick={() => {
										handleClick("am-AM");
										setNavbarVisible(false);
									}}
								>
									<img src={lng_hy} alt="Arpinet" />
								</button>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}
