import { Link } from 'react-router-dom';
import IsoSertificate from '../../../assets/docs/ISMS_ACCREDITED_Certificate+APPENDICES_ARPINET_EN_P-1.pdf';

export default function FooterLinksLegal(props) {
  return(
        <div className="col-12 text-center text-uppercase py-3">
            <div class="d-lg-flex d-grid justify-content-lg-between ">
            <Link className='me-3 text-muted my-2' to="/about_us">{props.t("_footer_links_about")}</Link>

            <Link className='me-3 text-muted my-2' to="/terms">{props.t("_footer_links_terms")}</Link>

            <Link className='me-3 text-muted my-2' to="/privacy_and_policy">{props.t("_footer_privacy_and_policy")}</Link>

            <Link className='me-3 text-muted my-2' to="/report">{props.t("_footer_links_reports")}</Link>

            <Link className='me-3 text-muted my-2' target="blank" to={IsoSertificate}>{props.t('_footer_links_certificate')}</Link>
            </div>
        </div>
);
}
