import React, {useEffect, useState} from "react";
import {FiFile} from "react-icons/fi";
import axios from "axios";
import MetaTags from "./ui-elements/MetaTags";
import Loading from "./Loader";

export default function ReportsPage(props) {
	const [reports, setReports] = useState([]);
	const [yearlyReports, setYearlyReports] = useState([]);

	useEffect(() => {
		axios.get(props.report_url).then(res => {
			setReports(res.data);
		});
		axios.get(props.terms_url).then(res => {
			setYearlyReports(res.data);
		});
	}, []);

	return (
		<>
			<MetaTags title={props.t("_reports_title")} />
			{reports ? (
				<section className="col-12 float-left p-0 pt-5 pb-5">
					<div className="container">
						<h3 className="gradient-orange-bg gradient-text text-uppercase text-center">
							{props.t("_reports_title")}
						</h3>
						<h6 className="text-muted text-center text-uppercase pb-5">
							{props.t("_reports_sub_title")}
						</h6>
						<div className="row">
							<div className="col-lg-8 col-12">
								{reports?.map((report, i) => (
									<div key={i} className="card b-radius b-shadow border-0 mb-5 p-4">
										<h3 className="text-center text-muted mb-3">- {report.title} -</h3>
										{report.file_qt1 ? (
											<a
												className="card flex-row b-radius b-shadow py-3 px-4 m-2 border d-inline-flex align-items-center text-muted"
												target="blank"
												href={report.file_qt1}
											>
												<FiFile className="me-2" />
												<span className="d-none d-sm-none d-md-none d-lg-block d-xl-block me-2 gradient-green-bg gradient-text">
													{props.t("_reports_company")}
												</span>
												{props.t("_reports_1st_quarter")}
											</a>
										) : null}
										{report.file_qt2 ? (
											<a
												className="card flex-row b-radius b-shadow py-3 px-4 m-2 border d-inline-flex align-items-center text-muted"
												target="blank"
												href={report.file_qt2}
											>
												<FiFile className="me-2" />
												<span className="d-none d-sm-none d-md-none d-lg-block d-xl-block me-2 gradient-green-bg gradient-text">
													{props.t("_reports_company")}
												</span>
												{props.t("_reports_2nd_quarter")}
											</a>
										) : null}
										{report.file_qt3 ? (
											<a
												className="card flex-row b-radius b-shadow py-3 px-4 m-2 border d-inline-flex align-items-center text-muted"
												target="blank"
												href={report.file_qt3}
											>
												<FiFile className="me-2" />
												<span className="d-none d-sm-none d-md-none d-lg-block d-xl-block me-2 gradient-green-bg gradient-text">
													{props.t("_reports_company")}
												</span>
												{props.t("_reports_3rd_quarter")}
											</a>
										) : null}
										{report.file_qt4 ? (
											<a
												className="card flex-row b-radius b-shadow py-3 px-4 m-2 border d-inline-flex align-items-center text-muted"
												target="blank"
												href={report.file_qt4}
											>
												<FiFile className="me-2" />
												<span className="d-none d-sm-none d-md-none d-lg-block d-xl-block me-2 gradient-green-bg gradient-text">
													{props.t("_reports_company")}
												</span>
												{props.t("_reports_4th_quarter")}
											</a>
										) : null}
									</div>
								))}
							</div>
							<div className="col-lg-4 order-lg-last order-first col-12">
								<div className="card b-radius b-shadow border-0 mb-5 p-3">
									{yearlyReports?.slice(1).map((term, i) =>
										term.term_files.map((item, i) => (
											<a
												key={i}
												className="card small b-radius b-shadow border-0 mb-3 py-lg-4 px-lg-2 py-4 px-4 text-muted"
												target="blank"
												href={item.file}
											>
												<span className="border-bottom pb-2">
													{props.t("_reports_yearly_report_description")}
													<span className="mx-1 text-success">{item.description}</span>
													{props.t("_reports_yearly_report_description_year")}
												</span>
												<br />
												<span className="gradient-green-bg gradient-text">
													{props?.t("_terms_and_conditions_download")}
												</span>
											</a>
										))
									)}
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				<div className="col-12 float-left p-0 pt-5 pb-5">
					<div className="container pb-5">
						<div className="card b-radius b-shadow border-0 mb-5 p-5">
							<div className="p-5">
								<Loading />
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
