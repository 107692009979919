import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {BASE_URL, USER_DATA, sliderURL} from "../../static/api";
import MetaTags from "../ui-elements/MetaTags";
import Loader from "../Loader";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import ErrorMessage from "./component/ErrorMessage";
import Agreements from "./component/Agreements";
import {Link, useLocation} from "react-router-dom";
import {FiX} from "react-icons/fi";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import Lottie from "lottie-react";
import success from "../../assets/lottiefiles/success.json";
import unsuccessful from "../../assets/lottiefiles/unsuccessful.json";
import {useLocalStorage} from "../../hooks/useStorage";

export default function UserAgreements(props) {
	const {t} = useTranslation();
	const toast = useRef(null);
	const [state, setState] = useState();
	const [slide, setSlide] = useState();
	const [error, setError] = useState();

	const [isShowingAlert, setIsShowingAlert, removeIsShowingAlert] = useLocalStorage("alert", false);
	const [paymentIsCompleted, setPaymentIsCompleted, removePaymentIsCompleted] = useLocalStorage(
		"paymentIsCompleted",
		false
	);

	useEffect(() => {
		loadData();
	}, []);

	function loadData() {
		axios.get(BASE_URL + USER_DATA).then(res => {
			setState(res?.data.data);
			setError(res?.data?.error);
		});
		axios.get(BASE_URL + sliderURL).then(res => {
			setSlide(res?.data[5]);
		});
	}
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const paidParam = queryParams.get("paid");

		if (paidParam) {
			if (paidParam === "true") {
				setPaymentIsCompleted(true);
			} else {
				setPaymentIsCompleted(false);
			}
		}
	}, [location]);

	function closeAlert() {
		removeIsShowingAlert();
		localStorage.setItem("alert", false);
	}

	return state ? (
		<>
			<MetaTags title={"Անձնական էջ"} desc={"Բաժանորդների անձնական էջ"} />
			<div className="col-sm-12">
				{isShowingAlert ? (
					paymentIsCompleted ? (
						<div className="row border-0 b-radius b-shadow mb-3">
							<div className="col-xl-11 col-12 d-flex justify-content-center align-items-center">
								<div className="row">
									<div className="col-xl-3 col-4 mx-auto text-center d-flex justify-content-center align-items-center">
										<Lottie
											animationData={success}
											loop={true}
											className="lottie_success d-flex justify-content-center align-items-center"
										/>
									</div>

									<div className="col-xl-9 col-lg-12 vstack d-flex justify-content-center text-xl-start text-center text-success text-uppercase fw-bold">
										<div className="fs-5 m-0">
											{props?.t("_user_page_online_payment_successfull_thank_you")}
										</div>
										<div className="fs-3 m-0">
											{props?.t("_user_page_online_payment_successfull_payment_success")}
										</div>
									</div>
								</div>
							</div>
							<div className="d-none d-xl-flex col-xl-1 col-12 text-end justify-content-center align-items-center">
								<Link
									to={"/user_account/dashboard"}
									className="btn btn-outline-success border rounded-circle d-flex justify-content-center align-items-center"
									style={{height: "50px", width: "50px"}}
									onClick={e => closeAlert()}
								>
									<FiX />
								</Link>
							</div>
							<div className="d-flex d-xl-none col-12 justify-content-center my-4">
								<Link
									to={"/user_account/dashboard"}
									className="btn btn-outline-success b-radius px-4 py-2 "
									onClick={e => closeAlert()}
								>
									{t("_close")}
								</Link>
							</div>
						</div>
					) : (
						<div className="row border-0 b-radius b-shadow mb-3">
							<div className="col-xl-11 col-12 d-flex justify-content-center align-items-center">
								<div className="row">
									<div className="col-xl-3 col-4 mx-auto text-center d-flex justify-content-center align-items-center">
										<Lottie
											animationData={unsuccessful}
											loop={true}
											className="lottie_unsuccessful d-flex justify-content-center align-items-center"
										/>
									</div>
									<div className="col-xl-9 col-lg-12 vstack d-flex justify-content-center text-xl-start text-center text-danger text-uppercase fw-bold">
										<div className="fs-5 m-0">
											{props?.t("_user_page_online_payment_not_successfull_headline")}
										</div>
										<div className="fs-3 m-0">
											{props?.t("_user_page_online_payment_not_successfull_payment_false")}
										</div>
										<div className=" m-0">
											{props?.t("_user_page_online_payment_not_successfull_help_text")}
										</div>
									</div>
								</div>
							</div>
							<div className="d-none d-xl-flex col-xl-1 col-12 text-end justify-content-center align-items-center">
								<Link
									to={"/user_account/dashboard"}
									className="btn btn-outline-danger border rounded-circle d-flex justify-content-center align-items-center"
									style={{height: "50px", width: "50px"}}
									onClick={e => closeAlert()}
								>
									<FiX />
								</Link>
							</div>
							<div className="d-flex d-xl-none col-12 justify-content-center my-4">
								<Link
									to={"/user_account/dashboard"}
									className="btn btn-outline-danger b-radius px-4 py-2 "
									onClick={e => closeAlert()}
								>
									{t("_close")}
								</Link>
							</div>
						</div>
					)
				) : null}
				{error === 0 ? (
					<>
						{state.agreements.map((agreements, index) => (
							<Agreements
								key={index}
								agreements={agreements}
								t={t}
								toast={toast}
								loadData={loadData}
							/>
						))}
					</>
				) : (
					<ErrorMessage />
				)}
			</div>
		</>
	) : (
		<Loader />
	);
}
