import React, {useState, useEffect} from "react";
import axios from "axios";
import {BASE_URL, feedback} from "../../static/api";
import {Link} from "react-router-dom";
import {FiArrowRight, FiArrowLeft} from "react-icons/fi";

export default function FeedBack(props) {
	const [choice, setChoice] = useState("");
	const [choiceText, setChoiceText] = useState(props?.t("_user_page_feedback_application_"));
	const [text, setText] = useState("");
	const [form, setFrom] = useState(true);

	function SendData(e) {
		e.preventDefault(e);
		if (text && choice) {
			axios({
				method: "POST",
				url: BASE_URL + feedback,
				data: {message: text, type: choice},
			}).then(res => {
				setFrom(false);
				setTimeout(() => {
					window.location.href = "/user_account";
				}, 5000);
			});
		}
	}
	function CencelSubmit(e) {
		setChoice("");
		setChoiceText();
		setText("");
	}

	useEffect(() => {
		setChoiceText(props?.t("_user_page_feedback_application_"));
	}, [localStorage.getItem("i18nextLng")]);

	return (
		<div className="row mb-3">
			<div className="col-sm-12">
				<div className="card border-0 b-radius b-shadow p-4 ">
					<div className="col-12 px-3 pt-2">
						<div>
							{props?.t("_user_page_feedback_user")}
							<span className="mx-2 d-inline-flex gradient-green-bg gradient-text fw-bold">
								{props?.user}
							</span>
							{props?.t("_user_page_feedback_welcome_line_01")}
							<br />
							{props?.t("_user_page_feedback_welcome_line_02")}
						</div>

						<div className="row">
							{form ? (
								<form
									className="col-md-12"
									onSubmit={e => {
										SendData(e);
										e.preventDefault(e);
									}}
								>
									<div className="row my-4">
										<div className="col-12 text-center">
											<div className="form-check form-check-inline d-inline-flex align-items-center mx-4">
												<input
													className="form-check-input"
													type="radio"
													name="feedback"
													checked={choice === "0"}
													value={choice}
													id="0"
													onChange={() => {
														setChoice("0");
													}}
												/>
												<label className="form-check-label mt-2 ms-3" for="0">
													{props?.t("_user_page_feedback_application_offer")}
												</label>
											</div>
											<div className="form-check form-check-inline d-inline-flex align-items-center mx-4">
												<input
													className="form-check-input"
													type="radio"
													name="feedback"
													checked={choice === "1"}
													value={choice}
													id="1"
													onChange={() => {
														setChoice("1");
													}}
												/>
												<label className="form-check-label mt-2 ms-3" for="1">
													{props?.t("_user_page_feedback_application_complaint")}
												</label>
											</div>
										</div>
									</div>

									<div className="row  my-4">
										<div className="col-lg-8 col-md-9 col-sm-10 col-12 text-center mx-auto">
											<textarea
												type="text"
												rows="6"
												style={{resize: "none"}}
												className="form-control b-radius b-shadow mt-3 text-muted"
												value={text}
												onChange={e => setText(e.target.value)}
											></textarea>
										</div>
									</div>

									<div className="row  my-4">
										<div className="col-lg-8 col-md-9 col-sm-10 col-12 text-center mx-auto mt-4">
											<Link
												to="/user_account"
												className="net-btn orange-bg orange-shadow py-2 px-5 m-2"
												onClick={e => CencelSubmit(e)}
											>
												<FiArrowLeft className="me-2" /> {props?.t("_cancel")}
											</Link>
											<button
												disabled={text && choice ? null : "disabled"}
												className={
													choice == "0"
														? "net-btn green-bg green-shadow py-2 px-5 m-2"
														: "net-btn red-bg red-shadow py-2 px-5 m-2"
												}
												onSubmit={e => SendData(e)}
											>
												{props?.t("_send")} <FiArrowRight className="ms-2" />
											</button>
										</div>
									</div>
								</form>
							) : (
								<div className="">
									<div className="text-center pt-5 pb-5 mt-3">
										<p className="green-text text-uppercase h4 pb-4">
											{props?.t("_call_req_done_line_1")}
										</p>
										<p className="text-muted">
											{props?.t("_user_page_feedback_user")} {props?.user}
											{choice === "0"
												? props?.t("_user_page_feedback_application_offer_response")
												: props?.t("_user_page_feedback_application_complaint_response")}
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
