import React from "react";
import {Link} from "react-router-dom";
import UserRate from "./UserRate";
import {
	FiUser,
	FiSmartphone,
	FiAtSign,
	FiArchive,
	FiCreditCard,
	FiCoffee,
	FiStar,
} from "react-icons/fi";

export default function UserSideBar(props) {
	const user_rated = localStorage.getItem("r");
	return (
		<div className="card w-100 b-radius b-shadow border-0 p-3">
			<span className="w-100">{props?.t("_user_page_sidebar_section_welcome")}</span>
			<span className="w-100  fw-bold">{props?.user}</span>
			<hr />
			<div className="w-100 p-0 text-start">
				<div className="d-flex justify-content-between align-items-center text-muted my-3">
					<span className="d-flex justify-content-center align-items-center">
						<FiUser className="me-3 small" />
						<span>{props?.t("_user_page_sidebar_section_subscriber")} (ID)</span>
					</span>
					<span className="fw-bold ">{props?.uid}</span>
				</div>
				<div className="d-flex justify-content-between align-items-center text-muted my-3">
					<span className="d-flex justify-content-center align-items-center">
						<FiSmartphone className="me-3 small" />
						<span>{props?.t("_user_page_sidebar_section_mobile_number")}</span>
					</span>

					<span className="fw-bold ">{props?.mobile}</span>
				</div>
				<div className="d-flex justify-content-between align-items-center text-muted my-3">
					<span className="d-flex justify-content-center align-items-center">
						<FiAtSign className="me-3 small" />
						<span className="d-inline-flex text-nowrap me-3">
							{props?.t("_user_page_sidebar_section_e_mail")}
						</span>
					</span>
					{props?.email == null ? (
						<span className="fw-bold text-danger">
							{props?.t("_user_page_sidebar_section_no_e_mail")}
						</span>
					) : (
						<span className="fw-bold text-truncate">{props?.email}</span>
					)}
				</div>
				<Link
					to={"/user_account/dashboard"}
					className="d-flex justify-content-between align-items-center text-muted my-3"
				>
					<span className="d-flex justify-content-center align-items-center">
						<FiArchive className="me-3 small" />
						<span>{props?.t("_user_page_sidebar_section_contracts")}</span>
					</span>
					<span>
						{props?.agreement} {props?.t("_user_page_sidebar_section_count")}
					</span>
				</Link>
				{/* <div className="d-flex pb-1 justify-content-between align-items-center text-muted my-3">
					<span className="d-flex justify-content-center align-items-center ">
						<FiCreditCard className="me-3 small" />
						<span>{props?.t("_user_page_sidebar_section_cards")}</span>
					</span>
					Visa
				</div> */}

				<Link to={"feedback"} className="d-flex pb-1 text-muted my-3">
					<span className="d-flex justify-content-center align-items-center ">
						<FiCoffee className="me-3 small" />
						<span>{props?.t("_user_page_sidebar_section_feedback")} </span>
					</span>
				</Link>

				{user_rated ? null : (
					<>
						<hr />
						<div className="d-flex justify-content-center align-items-center ">
							<UserRate t={props?.t} />
						</div>
					</>
				)}
			</div>
		</div>
	);
}
