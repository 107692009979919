import React, {useEffect, useState, useRef} from "react";
import {useTranslation} from "react-i18next";
import {BASE_URL, locationsURL, identify, identify_confirm, restart_data} from "../../static/api";
import axios from "axios";
import {isValidPhoneNumber} from "react-phone-number-input/mobile";
import InputMask from "react-input-mask";
import ApplicationMap from "./ApplicationMap";
import {REGX, ONLYNUMBERSREGX, NUMREGX} from "../../static/Static";
import MaskedInput from "react-text-mask";
import emailMask from "text-mask-addons/dist/emailMask";
import {
	FiUser,
	FiPhone,
	FiArrowRight,
	FiArrowLeft,
	FiMapPin,
	FiFile,
	FiPhoneIncoming,
	FiX,
} from "react-icons/fi";

export default function ApplicationRequest(props) {
	const {t} = useTranslation();
	const [load, setLoad] = useState(null);
	useEffect(() => {
		if (load == null) {
			setLoad(true);
		}
	});
	const PackID = props.data.id;
	const PackName = props.data.name;
	const PackPrice = props.data.price;

	const intervalRef = useRef();

	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState();

	//Additional Information Fields
	const [comment, setComment] = useState("");
	const [entry, setEntry] = useState("");
	const [floor, setFloor] = useState("");
	const [apt, setApt] = useState("");

	const [provinceID, setProvinceID] = useState("");
	const [region, setRegion] = useState([]);
	const [province, setProvince] = useState([]);
	const [error, setError] = useState("");
	const [userConfiramtion, setUserConfirmation] = useState("");
	const [identified, setindentified] = useState(false);
	const [mapState, setMapState] = useState(["40.4226651", "44.3097432"]);
	const [mapZoom, setMapZoom] = useState(10);

	const [passFront, setPF] = useState(null);
	const [passBack, setPB] = useState(null);
	const [selfie, setSelfie] = useState(null);

	const [forms, setForms] = useState([
		{
			mainForm: true,
			step1: true,
			step2: false,
			step3: false,
			step4: false,
			step5: false,
		},
	]);

	const [error_timer, setError_timer] = useState(0);
	const [progress, setProgress] = useState(0);

	function fatchRegions(e) {
		axios.get(BASE_URL + locationsURL).then(res => {
			setRegion(res.data);
			setForms({
				mainForm: true,
				step1: true,
				step2: false,
				step3: false,
				step4: false,
				step5: false,
			});
		});
	}

	function onRegionSelectChange(e) {
		setProvince([]);
		axios.get(BASE_URL + locationsURL + `?id=${e}`).then(res => {
			const provinces = [];
			res.data.forEach(obj => {
				obj.provinces.forEach(objNext => {
					provinces.push(objNext);
				});
			});
			setProvince(provinces);
			return provinces;
		});
	}

	function onProvinceSelectChange(e) {
		const get_province_value = e.target.value.split(",");
		localStorage.setItem("target", JSON.stringify([get_province_value[1], get_province_value[2]]));
		if (get_province_value) {
			setMapState([get_province_value[1], get_province_value[2]]);
			setMapZoom(15);
			setProvinceID(get_province_value[0]);
		}
		return <ApplicationMap />;
	}

	function identifyRequest(e) {
		e.preventDefault();
		if (isValidPhoneNumber(phone)) {
			axios({
				method: "POST",
				url: BASE_URL + identify,
				data: {mobile: "+" + phone.replace(NUMREGX, "")},
			})
				.then(res => {
					if (res.status === 200) {
						setForms({
							mainForm: false,
							step1: false,
							step2: false,
							step3: false,
							step4: true,
							step5: false,
						});
						setindentified(true);
					}
				})
				.catch(error => {
					setForms({
						mainForm: true,
						step1: false,
						step2: false,
						step3: true,
						step4: false,
						step5: false,
					});
					setindentified(false);
				});
		}
	}

	function SubmitCode(e) {
		e.preventDefault();
		axios({
			method: "POST",
			url: BASE_URL + identify_confirm,
			data: {mobile: phone, code: userConfiramtion},
		})
			.then(res => {
				if (res.status === 200) {
					onSubmit();
					setForms({
						lastForm: true,
						mainForm: true,
						step1: false,
						step2: false,
						step3: false,
						step4: false,
						step5: true,
					});
				}
			})
			.catch(error => {
				setForms({
					lastForm: false,
					mainForm: true,
					step1: false,
					step2: false,
					step3: false,
					step4: false,
					step5: true,
				});
				setError(error.response.data.error_code);
			});
	}

	function onSubmit(e) {
		const target = JSON.parse(localStorage.getItem("target"));
		var form_data = new FormData();
		form_data.append("name", name);
		form_data.append("email", email);
		form_data.append("mobile", "+" + phone.replace(NUMREGX, ""));
		form_data.append("tariff", props.data.name + " " + props.data.price);
		form_data.append("province", provinceID);
		form_data.append("latitude", target[0]);
		form_data.append("longitude", target[1]);
		form_data.append(
			"address",
			"Ավելացում քարտեզին` " +
				"մուտք " +
				entry +
				", " +
				"հարկ " +
				floor +
				", " +
				"բնակարան " +
				apt +
				", " +
				comment
		);
		form_data.append("selfie", selfie);
		form_data.append("passport1", passFront);
		form_data.append("passport2", passBack);
		axios
			.post(BASE_URL + restart_data, form_data, {
				headers: {"content-type": "multipart/form-data"},
			})
			.then(res => {
				localStorage.removeItem("target");
			});
	}

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setError_timer(t => t - 1);
		}, 1000);
		return () => clearInterval(intervalRef.current);
	}, [error_timer]);

	useEffect(() => {
		if (error_timer <= 0) {
			clearInterval(intervalRef.current);
		}
	}, [error_timer]);

	function ModalClose(e) {
		setForms({
			mainForm: false,
			step1: false,
			step2: false,
			step3: false,
			step4: false,
			step5: false,
		});
		setName("");
		setPhone("");
		setProvinceID("");
		setRegion([]);
		setProvince([]);
		setError("");
		setUserConfirmation("");
		setindentified(false);
		setMapState("40.4226651", "44.3097432");
		setMapZoom(10);
		setPF(null);
		setPB(null);
		setSelfie(null);
		setProgress(0);
	}
	function ModalOPEN(e) {
		fatchRegions(e);
		setForms({mainForm: true, step1: true, step2: false, step3: false, step4: false, step5: false});
		setProgress(0);
	}

	const regions = region.map(region => (
		<option value={region.id} key={region.id}>
			{region.name}
		</option>
	));
	const provinces = province.map(province => (
		<option
			id={province.id}
			value={[province.id, province.latitude, province.longitude]}
			key={province.id}
		>
			{province.name}
		</option>
	));

	return (
		<>
			<button
				className="addapplication text-center net-btn green-bg green-shadow py-3 px-4 m-2"
				data-bs-toggle="modal"
				data-bs-target={`#add-application${PackID}`}
				onClick={e => ModalOPEN(e)}
			>
				{t("_application_request")}
				<FiArrowRight className="ms-2" />
			</button>

			<div
				className="modal addapplication"
				id={`add-application${PackID}`}
				data-bs-backdrop="static"
				tabIndex="-1"
				role="dialog"
			>
				<div className="modal-dialog modal-lg modal-dialog-centered">
					<div className="modal-content bg-white p-3">
						<button
							type="button"
							className="close border-0"
							data-bs-dismiss="modal"
							onClick={e => ModalClose(e)}
						>
							<FiX />
						</button>

						{forms.mainForm ? (
							<div className="py-3">
								<div className="col-sm-12 p-0 pb-2 mt-3 text-center">
									{t("_application_request_you_have_choosen")}{" "}
									<span className="h4 font-weight-bold green-text mx-2">
										{" "}
										{PackName} {PackPrice}
									</span>{" "}
									{t("_application_request_you_have_choosen_a_plan")}
									<br />
									{error ? (
										<span className="text-danger">{t("_application_request_fill_all_fields")}</span>
									) : (
										<span className="text-muted">{t("_application_request_fill_all_fields")}</span>
									)}
								</div>

								<div className="col-xl-10 col-lg-10 col-11 mx-auto p-0 my-3 position-relative">
									<ul className="d-flex justify-content-between align-items-center">
										<span className={progress >= 0 ? "form-icon active" : "form-icon"}>
											<FiUser />
										</span>
										<span className={progress >= 34 ? "form-icon active" : "form-icon"}>
											<FiMapPin />
										</span>
										<span className={progress >= 68 ? "form-icon active" : "form-icon"}>
											<FiFile />
										</span>
										<span className={progress >= 100 ? "form-icon active" : "form-icon"}>
											<FiPhone />
										</span>
									</ul>
									<div className="progress">
										<div
											className="progress-bar bg-success"
											role="progressbar"
											aria-valuemin="0"
											aria-valuemax="100"
											aria-valuenow={progress}
											style={{width: progress + "%"}}
										></div>
									</div>
								</div>

								<form
									onSubmit={
										name &&
										provinceID &&
										region &&
										passFront &&
										passBack &&
										selfie &&
										isValidPhoneNumber(phone)
											? e => identifyRequest(e)
											: null
									}
									className="application col-sm-11 mx-auto p-0"
								>
									{forms.step1 ? (
										<div className="col-12 p-0 mt-5">
											<div className="d-md-flex d-sm-block row">
												<div className="col-xl-6 col-lg-6 col-md-6 col-12">
													<div className="form-floating my-3 mx-2">
														<input
															type="text"
															className="form-control b-radius b-shadow  text-muted"
															id="name"
															placeholder={t("_application_request_name_")}
															required
															value={name}
															onChange={e => setName(e.target.value.replace(REGX, ""))}
														/>
														<label htmlFor="name">{t("_application_request_name_")}</label>
													</div>
												</div>
												<div className="col-xl-6 col-lg-6 col-md-6 col-12">
													<div className="form-floating my-3 mx-2">
														<InputMask
															type="tel"
															className={
																error.mobile
																	? "form-control b-radius b-shadow text-muted is-invalid text-truncate"
																	: "form-control b-radius b-shadow text-muted text-truncate"
															}
															id="phone"
															required
															value={phone}
															mask="+374 (99) 99-99-99"
															placeholder="+374 (99) 99-99-99"
															onChange={e => setPhone(e.target.value.replace(NUMREGX, ""))}
														/>
														<label htmlFor="phone">{t("_mobile")}</label>
													</div>
												</div>
												<div className="col-12">
													<div className="form-floating my-3 mx-2">
														<MaskedInput
															type="text"
															className={"form-control b-radius mt-2"}
															id="email"
															required
															mask={emailMask}
															value={email}
															onChange={e => setEmail(e.target.value)}
															placeholder="someone@example.com"
														/>

														<label htmlFor="email">{t("_email")}</label>
													</div>
												</div>
											</div>
											<div className="d-md-flex d-sm-block">
												<div className="col-xl-6 col-lg-6 col-md-6 col-12">
													<div className="form-floating my-3 mx-2">
														<select
															id="region"
															onChange={e => onRegionSelectChange(e.target.value)}
															className="form-control b-radius b-shadow text-muted"
															required
														>
															<option disabled selected>
																{t("_application_request_please_select_location")}
															</option>
															{regions}
														</select>
														<label htmlFor="region">
															{t("_application_request_select_location")}
														</label>
													</div>
												</div>
												<div className="col-xl-6 col-lg-6 col-md-6 col-12">
													<div className="form-floating my-3 mx-2">
														<select
															id="select_province"
															onChange={e => onProvinceSelectChange(e)}
															className={
																error.province
																	? "form-control b-radius b-shadow text-muted is-invalid"
																	: "form-control b-radius b-shadow text-muted"
															}
															required
														>
															<option disabled selected>
																{t("_application_request_please_select_province")}
															</option>
															{provinces}
														</select>
														<label htmlFor="select_province">
															{t("_application_request_select_province")}
														</label>
													</div>
												</div>
											</div>
											<div className="col-sm-12 mx-auto mt-5 mb-2 text-center">
												<button
													type="button"
													onClick={e => {
														setForms({
															mainForm: true,
															step1: false,
															step2: true,
															step3: false,
															step4: false,
															step5: false,
														});
														setProgress(34);
													}}
													disabled={
														name && provinceID && region && isValidPhoneNumber(phone) ? null : true
													}
													className="net-btn green-bg green-shadow py-3 px-4 m-2"
												>
													{t("_next_step")} <FiArrowRight className="ms-2" />
												</button>
											</div>
										</div>
									) : null}

									{forms.step2 ? (
										<div className="col-sm-12 p-0 mt-5 text-center">
											<div className="row">
												<div className="text-danger fst-italic my-3">
													{t("_application_request_address_description")}
												</div>
												<div
													style={{width: "100%", height: "300px"}}
													className="mt-2 mb-2 card b-radius b-shadow overflow-hidden p-0"
												>
													<ApplicationMap
														data={[mapState, mapZoom]}
														style={{width: "100%", height: "300px"}}
													/>
												</div>
												<div className="row mt-3">
													<div className="text-dark">
														{t("_application_request_address_apartment_description")}
													</div>
													<div className="col-md-4 col-sm-6 col-6">
														<div className="form-floating my-3">
															<input
																type="tel"
																className="form-control b-radius b-shadow text-muted"
																id="entry"
																required
																value={entry}
																onChange={e =>
																	setEntry(e.target.value.replace(ONLYNUMBERSREGX, ""))
																}
															/>
															<label htmlFor="entry">{t("_application_request_entrance")}</label>
														</div>
													</div>
													<div className="col-md-4 col-sm-6 col-6">
														<div className="form-floating my-3">
															<input
																type="tel"
																className="form-control b-radius b-shadow text-muted"
																id="floor"
																required
																value={floor}
																onChange={e =>
																	setFloor(e.target.value.replace(ONLYNUMBERSREGX, ""))
																}
															/>
															<label htmlFor="floor">{t("_application_request_floor")}</label>
														</div>
													</div>
													<div className="col-md-4 col-sm-6 col-6">
														<div className="form-floating my-3">
															<input
																type="tel"
																className="form-control b-radius b-shadow text-muted"
																id="apartment"
																required
																value={apt}
																onChange={e => setApt(e.target.value.replace(ONLYNUMBERSREGX, ""))}
															/>
															<label htmlFor="apartment">
																{t("_application_request_apartment")}
															</label>
														</div>
													</div>
													<div className="text-dark">{t("_application_request_comments_info")}</div>

													<div className="col-md-12 col-sm-6 col-6">
														<div className="form-floating my-3">
															<input
																type="tel"
																className="form-control b-radius b-shadow text-muted"
																id="comment"
																required
																value={comment}
																onChange={e => setComment(e.target.value)}
															/>
															<label htmlFor="comment">{t("_application_request_comments")}</label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-12 mx-auto mt-5 mb-2 text-center">
												<button
													type="button"
													onClick={e => {
														setForms({
															mainForm: true,
															step1: true,
															step2: false,
															step3: false,
															step4: false,
															step5: false,
														});
														setProgress(0);
													}}
													className="net-btn orange-bg orange-shadow py-3 px-4 m-1"
												>
													<FiArrowLeft className="me-2" /> {t("_user_page_login_go_back")}{" "}
												</button>
												<button
													onClick={e => {
														setForms({
															mainForm: true,
															step1: false,
															step2: false,
															step3: true,
															step4: false,
															step5: false,
														});
														setProgress(68);
													}}
													className="net-btn green-bg green-shadow py-3 px-4 m-2"
												>
													{t("_next_step")}
													<FiArrowRight className="ms-2" />
												</button>
											</div>
										</div>
									) : null}

									{forms.step3 ? (
										<div className="row mt-5">
											<div className="col-10 mx-auto mt-2 mb-2">
												<div className="text-muted small p-0 mb-2 mt-1 text-start d-grid">
													{t("_application_request_pass_first_page")}
													<span className="text-danger small fst-italic">
														{t("_application_request_file_exapt")}
													</span>
												</div>
												<label htmlFor="passFront" className="application_input b-radius b-shadow">
													<div className="input_image py-2">
														<input
															type="file"
															id="passFront"
															name="passport1"
															max-size="2097152"
															accept="image/x-png,image/jpg,image/jpeg"
															onChange={e => setPF(e.target.files[0])}
														/>

														<span role="button">{t("_application_request_attach_file")}</span>
													</div>
													<div className="application_text text-truncate ">{passFront?.name}</div>
												</label>
											</div>

											<div className="col-10 mx-auto mt-2 mb-2">
												<div className="text-muted small p-0 mb-2 mt-1 text-start d-grid">
													{t("_application_request_pass_second_page")}
													<span className="text-danger small fst-italic">
														{t("_application_request_file_exapt")}
													</span>
												</div>
												<label htmlFor="passBack" className="application_input b-radius b-shadow">
													<div className="input_image py-2">
														<input
															type="file"
															id="passBack"
															max-size="2097152"
															accept="image/x-png,image/jpg,image/jpeg"
															onChange={e => setPB(e.target.files[0])}
														/>
														<span role="button">{t("_application_request_attach_file")}</span>
													</div>
													<div className="application_text text-truncate">{passBack?.name}</div>
												</label>
											</div>

											<div className="col-10 mx-auto mt-2 mb-2">
												<div className="text-muted small p-0 mb-2 mt-1 text-start d-grid">
													{t("_application_request_pass_selfie")}
													<span className="text-danger small fst-italic">
														{t("_application_request_file_exapt_only_jpg")}
													</span>
												</div>
												<label htmlFor="selfie" className="application_input b-radius b-shadow">
													<div className="input_image py-2">
														<input
															type="file"
															id="selfie"
															max-size="2097152"
															accept="image/jpg,image/jpeg"
															onChange={e => setSelfie(e.target.files[0])}
														/>
														<span role="button">{t("_application_request_attach_file")}</span>
													</div>
													<div className="application_text text-truncate">{selfie?.name}</div>
												</label>
											</div>
											<div className="col-sm-12 mx-auto mt-5 mb-2 text-center">
												<button
													type="button"
													onClick={e => {
														setForms({
															mainForm: true,
															step1: false,
															step2: true,
															step3: false,
															step4: false,
															step5: false,
														});
														setProgress(68);
													}}
													className="net-btn orange-bg orange-shadow py-3 px-4 m-1"
												>
													<FiArrowLeft className="me-2" /> {t("_user_page_login_go_back")}{" "}
												</button>
												<button
													onClick={e => {
														setForms({
															mainForm: false,
															step1: false,
															step2: false,
															step3: false,
															step4: true,
															step5: false,
														});
														setProgress(100);
														setError_timer(60);
														identifyRequest(e);
														setUserConfirmation("");
													}}
													disabled={passBack && passFront && selfie ? null : true}
													className="net-btn green-bg green-shadow py-3 px-4 m-2"
												>
													{t("_application_request_confirm_info")}
													<FiArrowRight className="ms-2" />
												</button>
											</div>
										</div>
									) : null}
								</form>
							</div>
						) : null}

						{identified && forms.step4 ? (
							<form className="modal-body p-2" onSubmit={e => SubmitCode(e)}>
								<div className="text-center mb-4">
									{t("_request_text_line_1")} 060XX<span className="text-danger">XXXX</span>{" "}
									{t("_request_text_line_2")}
									<div className="small mt-2 col-12">
										{error_timer > 0 ? (
											<>
												{t("_request_error_text")} {error_timer} {t("_request_error_seconds")}{" "}
											</>
										) : (
											<button
												onClick={e => {
													identifyRequest(e);
													setError_timer(60);
												}}
												className="btn green-text mt-2 p-0 m-0"
											>
												<FiPhoneIncoming className="me-2" /> {t("_request_repeat")}
											</button>
										)}
									</div>
									{error ? (
										<>
											<br />
											<span className="text-danger">{t("_request_error")}</span>
										</>
									) : null}
								</div>

								<div className="form-floating mb-5 mt-3">
									<InputMask
										type="tel"
										required
										autoFocus
										className="form-control b-radius p-2 pl-4 pr-4 text-center fw-bold ls-30 mb-3"
										name="userConfiramtion"
										id="userConfiramtion"
										mask="9999"
										placeholder="_ _ _ _"
										value={userConfiramtion}
										onChange={e => setUserConfirmation(e.target.value.replace(NUMREGX, ""))}
									/>
								</div>

								<div className="col-sm-12 mx-auto mt-4 mb-2 text-center">
									<button
										type="button"
										onClick={e => {
											setForms({
												mainForm: true,
												step1: false,
												step2: false,
												step3: true,
												step4: false,
												step5: false,
											});
											setProgress(68);
										}}
										className="net-btn orange-bg orange-shadow py-3 px-4 m-1"
									>
										<FiArrowLeft className="me-2" /> {t("_user_page_login_go_back")}{" "}
									</button>

									<button
										type="submit"
										className="net-btn green-bg green-shadow py-3 px-4 m-2"
										onClick={
											userConfiramtion.length === 4
												? e => {
														SubmitCode(e);
														setForms({step5: true});
												  }
												: null
										}
										disabled={userConfiramtion.length === 4 ? false : true}
									>
										{t("_confirm")} <FiArrowRight className="ms-2" />
									</button>
								</div>
							</form>
						) : null}

						{forms.step5 ? (
							<div className="d-flex justify-content-center align-items-center my-5">
								<div className="text-center">
									<p className="green-text text-uppercase h4 ">{t("_call_req_done_line_1")}</p>
									<hr />
									<p className="text-muted">{t("_call_req_done_line_2")}</p>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
}
