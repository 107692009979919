import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = (url) => {
  const lng = localStorage.getItem('i18nextLng')
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url, { headers: { "Accept-Language": lng.substring(0, 2) } });
        if (isMounted, lng) {
          setLoading(false)
          setData(response.data);
        }
      } catch (error) {
        if (isMounted, lng) {
          setError(error);
        }
      } finally {
        if (isMounted, lng) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      // Cleanup function to set isMounted to false when the component is unmounted
      setIsMounted(false);
    };
  }, [url, lng, isMounted]);

  return { data, loading, error };
};

export default useFetch;
