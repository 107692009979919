import React, {useState, useEffect} from "react";
import axios from "axios";
import {BASE_URL, ratingURL} from "../../static/api";
import {FiArrowRight} from "react-icons/fi";
import Loading from "../Loader";

export default function UserRate(props) {
	const [rating, setRating] = useState(0);
	const [hover, setHover] = useState(0);
	const [mainForm, setMainForm] = useState(true);
	const [form, setForm] = useState(false);
	const [text, setText] = useState("");
	const [ratingText, setRatingText] = useState("");
	const [subscriberComment, setSubscriberComment] = useState("");
	const [loading, setLoading] = useState(null);
	const [btn, setBtn] = useState(false);

	useEffect(() => {
		switch (rating) {
			case 5:
				return (
					setForm(false),
					setText(props?.t("_user_page_rating_5_description")),
					setBtn(true),
					setRatingText(props?.t("_user_page_rating_5_"))
				);
			case 4:
				return (
					setForm(true),
					setText(props?.t("_user_page_rating_4_description")),
					setBtn(true),
					setRatingText(props?.t("_user_page_rating_4_") + ", մեկնաբնություն՝ " + subscriberComment)
				);
			case 3:
				return (
					setForm(true),
					setText(props?.t("_user_page_rating_3_description")),
					setBtn(true),
					setRatingText(props?.t("_user_page_rating_3_") + ", մեկնաբնություն՝ " + subscriberComment)
				);
			case 2:
				return (
					setForm(true),
					setText(props?.t("_user_page_rating_2_description")),
					setBtn(true),
					setRatingText(props?.t("_user_page_rating_2_") + ", մեկնաբնություն՝ " + subscriberComment)
				);
			case 1:
				return (
					setForm(true),
					setText(props?.t("_user_page_rating_1_description")),
					setBtn(true),
					setRatingText(props?.t("_user_page_rating_1_") + ", մեկնաբնություն՝ " + subscriberComment)
				);
			default:
				return null;
		}
	}, [rating, subscriberComment, localStorage.getItem("i18nextLng")]);
	function sendRating(e) {
		if (rating && ratingText) {
			axios({
				method: "POST",
				url: BASE_URL + ratingURL,
				data: {type: rating, message: ratingText},
			}).then(res => {
				localStorage.setItem("r", "true");
				setLoading(true);
				setTimeout(() => {
					setForm(false);
					setLoading(false);
					setMainForm(false);
					document.getElementById("rating_wrapper").remove();
				}, 3000);
			});
		}
	}

	return (
		<div id="rating_wrapper">
			<div className="col-12 p-0 text-center h5 mb-4">{props?.t("_user_page_rating_headline")}</div>
			<div className="star-rating text-center">
				{[...Array(5)].map((star, index) => {
					index += 1;
					return (
						<button
							type="button"
							key={index}
							className={index <= (hover || rating) ? "on" : "off"}
							onClick={() => setRating(index)}
							onMouseEnter={() => setHover(index)}
							onMouseLeave={() => setHover(rating)}
						>
							<span className="star">&#9733;</span>
						</button>
					);
				})}
			</div>
			<div className="col-12 text-center mt-4">
				{loading ? (
					<Loading />
				) : (
					<>
						{mainForm ? (
							<div>
								<form
									className="col-12 p-0"
									onSubmit={e => {
										sendRating(e);
										e.preventDefault(e);
									}}
								>
									{text}
									{form ? (
										<div className=" mb-4 mt-3 col-sm-12">
											<textarea
												type="text"
												rows="6"
												style={{resize: "none"}}
												className="form-control b-radius b-shadow mt-3 text-muted"
												value={subscriberComment}
												onChange={e => setSubscriberComment(e.target.value)}
											></textarea>
										</div>
									) : null}
									{btn ? (
										<button
											className="net-btn green-bg green-shadow py-2 px-5 m-2"
											onSubmit={e => sendRating(e)}
										>
											{props?.t("_send")} <FiArrowRight className="ms-2" />
										</button>
									) : null}
								</form>
							</div>
						) : null}
					</>
				)}
			</div>
		</div>
	);
}
