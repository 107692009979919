import {Link} from "react-router-dom";
import logo from "../../../assets/images/logo_main.svg";
import instagram_icon from "../../../assets/images/icons/instagram-logo.svg";
import facebook_icon from "../../../assets/images/icons/facebook-logo.svg";
import linkedin_icon from "../../../assets/images/icons/linkedin-logo.svg";

export default function FooterLogoContainer(props) {
	return (
		<div className="col-xl-4 col-12 pl-lg-0 py-3 mb-xl-0 mb-5 text-xl-start text-center">
			<img className="text-center" src={logo} style={{maxWidth: "300px"}} />
			<div className="row">
				<div className="col-12 text-xl-start text-center mb-lg-0 mb-3">
					<div className="m-0 fw-bold mt-3">
						{props.t("_footer_addr_official_address")}
						<br />
						{props.t("_footer_addr_official_address_line")}
					</div>
					<div className="d-md-flex d-block justify-content-xl-start justify-content-center text-muted my-2">
						<div className="fw-bold my-md-0 my-3">
							<a className="text-muted d-inline-flex" href="tel:+37460888888">
								+37460888888
							</a>
						</div>
						<div className="mx-3 d-none d-md-block">|</div>
						<div className="fw-bold my-md-0 my-3">
							<a className="text-muted" href="mailto:info@arpinet.am">
								info@arpinet.am
							</a>
						</div>
						<div className="mx-3 d-none d-md-block">|</div>
						<div className="fw-bold my-md-0 my-3">
							<a className="text-muted" href="mailto:hr@arpinet.am">
								hr@arpinet.am
							</a>
						</div>
					</div>
					<div className="row mt-4 d-flex justify-content-xl-start justify-content-center text-xl-start text-center">
						<a target="blank" href="https://x.com/kartig_1985/" className="social-links mx-1">
							<img src={facebook_icon} className="w-100" />
						</a>
						<a target="blank" href="https://x.com/kartig_1985/" className="social-links mx-1">
							<img src={instagram_icon} className="w-100" />
						</a>
						<a
							target="blank"
							href="https://www.linkedin.com/in/kartig1985/"
							className="social-links mx-1"
						>
							<img src={linkedin_icon} className="w-100" />
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
