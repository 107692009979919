import React from "react";
import {alt_mobile_tv} from "../../static/Static";
import {FiLogIn, FiArrowRight} from "react-icons/fi";
import main_bg_image from "../../assets/images/mobile-tv/slider_bg.jpg";
import tv_box from "../../assets/images/mobile-tv/svg_tv_box.svg";
import smart_tv from "../../assets/images/mobile-tv/svg_smart_tv.svg";
import mobile_devices from "../../assets/images/mobile-tv/svg_mobile_devices.svg";
import android_tv from "../../assets/images/mobile-tv/svg_android_tv.svg";

import inform from "../../assets/images/mobile-tv/01_inform.svg";
import local from "../../assets/images/mobile-tv/02_local.svg";
import entertaiment from "../../assets/images/mobile-tv/03_entertaiment.svg";
import sport from "../../assets/images/mobile-tv/04_sport.svg";
import kids from "../../assets/images/mobile-tv/05_kids.svg";
import movies from "../../assets/images/mobile-tv/06_movies.svg";
import music from "../../assets/images/mobile-tv/07_music.svg";
import edu from "../../assets/images/mobile-tv/08_edu.svg";
import app_store from "../../assets/images/mobile-tv/app-store.png";
import lg_store from "../../assets/images/mobile-tv/lg-store.png";
import sm_store from "../../assets/images/mobile-tv/sm-store.png";
import play_store from "../../assets/images/mobile-tv/playstore.png";

import MetaTags from "../ui-elements/MetaTags";
import PaymentOptions from "../ui-elements/PaymentOptions";

export default function MobileTV(props) {
	return (
		<>
			<MetaTags title={"Mobile TV"} />

			<div className="col-12 p-0">
				<div className="container pb-0">
					<img src={main_bg_image} alt={alt_mobile_tv} />
				</div>
				<div className="sep-line mt-1"></div>
			</div>

			<div className="col-sm-12 col-xs-12 p-0 pt-4 pb-5 text text-justify">
				<div className="container pt-2 pb-5">
					<span className="gradient-green-bg gradient-text fw-bolder">ARPINET TV </span>{" "}
					{props.t("_mobile_tv_main_description_line_1")}
				</div>

				<div className="col-sm-12 col-xs-12 p-0">
					<div className="container">
						<h4 className="text-center text-uppercase">
							{props.t("_mobile_tv_main_subline_1")}
							<span className="gradient-green-bg gradient-text fw-bolder"> ARPINET TV </span>
							{props.t("_mobile_tv_main_subline_2")}
						</h4>
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-justify mt-4">
								<div className="pr-lg-4 pr-sm-0">
									{props.t("_mobile_tv_main_description_line_2")}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-justify mt-4">
								<div className="pl-lg-4 pl-sm-0">
									{props.t("_mobile_tv_main_description_line_3")}
								</div>
							</div>
						</div>
						<div className="row pt-5 pb-5 p-0">
							<div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 stretch-card p-0">
								<a
									href="#"
									className="card b-radius b-shadow border-0 cursor-default text-center m-3 p-2"
								>
									<img
										alt={alt_mobile_tv}
										className="col-lg-12 col-md-10 col-sm-8 col-6 mx-auto p-3"
										src={tv_box}
									/>
									<p className="text-center uppercase small mt-4 mb-0 text-muted">
										MAG | ELTEX | TVIP | Dune
									</p>
									<p className="text-justify p-3 small text-muted m-0">
										{props.t("_mobile_tv_steps_1")}
									</p>
								</a>
							</div>

							<div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 stretch-card p-0">
								<a
									href="#"
									className="card b-radius b-shadow border-0 cursor-default text-center m-3 p-2"
								>
									<img
										alt={alt_mobile_tv}
										className="col-lg-12 col-md-10 col-sm-8 col-6 mx-auto p-3"
										src={smart_tv}
									/>
									<p className="text-center uppercase small mt-4 mb-0 text-muted">
										Samsung Smart TV | LG Smart TV
									</p>
									<p className="text-justify p-3 small text-muted m-0">
										{props.t("_mobile_tv_steps_2")}
									</p>
								</a>
							</div>

							<div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 stretch-card p-0">
								<a
									href="#"
									className="card b-radius b-shadow border-0 cursor-default text-center m-3 p-2"
								>
									<img
										alt={alt_mobile_tv}
										className="col-lg-12 col-md-10 col-sm-8 col-6 mx-auto p-3"
										src={android_tv}
									/>
									<p className="text-center uppercase small mt-4 mb-0 text-muted">
										Android Smart TV | Android TV box
									</p>
									<p className="text-justify p-3 small text-muted m-0">
										{props.t("_mobile_tv_steps_3")}
									</p>
								</a>
							</div>

							<div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 stretch-card p-0">
								<a
									href="#"
									className="card b-radius b-shadow border-0 cursor-default text-center m-3 p-2"
								>
									<img
										alt={alt_mobile_tv}
										className="col-lg-12 col-md-10 col-sm-8 col-6 mx-auto p-3"
										src={mobile_devices}
									/>
									<p className="text-center uppercase small mt-4 mb-0 text-muted">
										Google Play | Apple app store
									</p>
									<p className="text-justify p-3 small text-muted m-0">
										{props.t("_mobile_tv_steps_4")}
									</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="col-sm-12 col-xs-12 p-0 position-relative mobile-tv-bg paralax">
				<div className="container pt-5 pb-5 ">
					<div className="card b-shadow b-radius border-0 article mt-5 mb-5 p-5 inner-section">
						<div className="row p-0 m-0">
							<div className="col-sm-12 col-xs-12 text-muted pb-3 mb-3 p-0">
								<h4 className="w-100 text-center pt-3 pb-5">
									{props.t("_mobile_tv_channel_types_headline")}
								</h4>
								<div className="row p-0 m-0">
									<div className="col-lg-3 col-md-6 col-sm-12 p-0 text-md-start text-center text-uppercase">
										<p>
											<img src={inform} alt={alt_mobile_tv} />
											{props.t("_mobile_tv_channel_types_01")}
										</p>
										<p>
											<img src={local} alt={alt_mobile_tv} />
											{props.t("_mobile_tv_channel_types_02")}
										</p>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-12 p-0 text-md-start text-center text-uppercase">
										<p>
											<img src={entertaiment} alt={alt_mobile_tv} />
											{props.t("_mobile_tv_channel_types_03")}
										</p>
										<p>
											<img src={sport} alt={alt_mobile_tv} />
											{props.t("_mobile_tv_channel_types_04")}
										</p>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-12 p-0 text-md-start text-center text-uppercase">
										<p>
											<img src={kids} alt={alt_mobile_tv} />
											{props.t("_mobile_tv_channel_types_05")}
										</p>
										<p>
											<img src={movies} alt={alt_mobile_tv} />
											{props.t("_mobile_tv_channel_types_06")}
										</p>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-12 p-0 text-md-start text-center text-uppercase">
										<p>
											<img src={music} alt={alt_mobile_tv} />
											{props.t("_mobile_tv_channel_types_07")}
										</p>
										<p>
											<img src={edu} alt={alt_mobile_tv} />
											{props.t("_mobile_tv_channel_types_08")}
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="row p-0 m-0">
							<div className="col-lg-5 col-sm-12 col-12 text-muted pricing p-0 stretch-card">
								<div className="col-sm-12 col-12 p-2 stretch-card">
									<div className="card w-100 b-shadow b-radius border-0 green border-0 text-center p-5">
										<h3 className="orange-text">Mobile TV</h3>
										<p className="small text-muted text-center w-100 mb-0 pb-0 mt-2 pt-2">
											{props.t("_tariff_monthly_payment")}
										</p>
										<div className="display-1 fw-bold mt-0 pt-0">3800</div>
										<div className="inet-info col-12 pt-3">
											<h1>3</h1>
											<ul className="till-wrapper pt-3">
												<li>{props.t("_tariff_type_smart")} </li>
												<li>{props.t("_tariff_type_smart_device")}</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-7 col-sm-12 col-xs-12 text-muted p-0 stretch-card">
								<div className="col-sm-12 col-xs-12 p-2 stretch-card">
									<div className="card w-100 b-shadow b-radius border-0 article border-0 text-center pricing p-5 ">
										<h4 className="text-center mb-0 col-12 text-uppercase">
											{props.t("_mobile_tv_subscribe_now")}
										</h4>
										<div className="display-1 fw-bold col-12 text-center pt-3 green-text pb-2">
											{props.t("_mobile_tv_seven_days")}
										</div>
										<h4 className="text-center mb-0 col-12 orange-text">
											{props.t("_mobile_tv_free")}
										</h4>
										<div className="col-sm-12 col-xs-12 text-center p-0 pt-5 pb-2 my-4">
											<a
												className="net-btn green-bg green-shadow py-3 px-4 m-3"
												target="blank"
												href="https://arpinet.tv/"
											>
												{props.t("_arpicam_login")}
												<FiLogIn className="ms-2" />{" "}
											</a>
											<a
												className="net-btn orange-bg orange-shadow py-3 px-4 m-3"
												target="blank"
												href="https://arpinet.tv/"
											>
												{props.t("_mobile_tv_subscribe")}
												<FiArrowRight className="ms-2" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="col-sm-12 col-xs-12 p-0">
				<div className="container pt-4 ">
					<div className="card b-radius b-shadow border-0 mt-5 mb-4 p-5">
						<div className="row">
							<div className="col-sm-12 col-xs-12 text-muted mb-3">
								<div className="col-md-6 col-sm-9 float-none mx-auto">
									<h5 className="w-100 text-center pt-3 pb-5">
										<span className="gradient-green-bg gradient-text fw-bolder">ARPINET TV</span>{" "}
										{props.t("_mobile_tv_application_is_avaible")}
									</h5>
									<div className="row">
										<div className="col-sm-6 text-center">
											<a href="https://samsung.com" target="blank">
												<img
													className="mt-2 mb-1 col-sm-12 col-xs-8 p-1"
													src={lg_store}
													alt={alt_mobile_tv}
												/>
											</a>
											<a
												href="https://ru.lgappstv.com/main/tvapp/detail?appId=455499"
												target="blank"
											>
												<img
													className="mt-1 mb-2 col-sm-12 col-sm-8 p-1"
													src={sm_store}
													alt={alt_mobile_tv}
												/>
											</a>
										</div>
										<div className="col-sm-6 text-center p-1">
											<a
												href="https://apps.apple.com/us/app/arpinet-tv/id1289560227"
												target="blank"
											>
												<img
													className="mt-2 mb-2 col-sm-5 col-sm-4 col-4 p-2"
													src={app_store}
													alt={alt_mobile_tv}
												/>
											</a>
											<a
												href="https://play.google.com/store/apps/details?id=am.arpinet.iptvv2"
												target="blank"
											>
												<img
													className="mt-2 mb-2 col-sm-5 col-sm-4 col-4 p-2"
													src={play_store}
													alt={alt_mobile_tv}
												/>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="col-sm-12 col-xs-12 mt-2 mb-2 p-0 pb-4">
				<div className="container pt-4 pb-2">
					<div className="card b-radius b-shadow border-0 mb-4 p-5">
						<div className="row">
							<PaymentOptions t={props.t} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
