import React, {useState, useEffect} from "react";
import axios from "axios";
import {BASE_URL, POSTS_URL} from "../static/api";
import {Link} from "react-router-dom";
import {FiChevronsRight, FiChevronsLeft, FiArrowRight} from "react-icons/fi";
import {useTranslation} from "react-i18next";

function PostsPagination() {
	const lng = localStorage.getItem("i18nextLng");
	const {t} = useTranslation();
	const [posts, setPosts] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalCount, setTotalCount] = useState(0);
	const itemsPerPage = 10;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const currentPageURL = BASE_URL + POSTS_URL + `?page=${currentPage}`;
		setLoading(true);
		axios
			.get(currentPageURL, {headers: {"Accept-Language": lng.substring(0, 2)}})
			.then(res => {
				setPosts(res.data.results);
				setTotalCount(res.data.count);
			})
			.catch(error => {})
			.finally(setLoading(false));
	}, [currentPage, lng]);

	const totalPages = Math.ceil(totalCount / itemsPerPage);

	const getPageNumbers = () => {
		const pageNumbers = [];
		let startPage, endPage;
		if (totalPages <= 4) {
			startPage = 1;
			endPage = totalPages;
		} else {
			if (currentPage <= 4) {
				startPage = 1;
				endPage = 4;
			} else if (currentPage + 2 >= totalPages) {
				startPage = totalPages - 5;
				endPage = totalPages;
			} else {
				startPage = currentPage - 1;
				endPage = currentPage + 1;
			}
		}
		if (startPage > 1) {
			pageNumbers.push(1, "...");
		}
		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}
		if (endPage < totalPages) {
			pageNumbers.push("...", totalPages);
		}
		return pageNumbers;
	};

	if (loading) return <div>Loading...</div>;

	return (
		<div className="container mt-3">
			<div className="container mt-5">
				{posts.map((post, index) => (
					<Link
						to={`/news/${post.id}`}
						className="col-sm-12 card b-radius b-shadow p-0 mb-4 news-item overflow-hidden border-0"
						key={index}
					>
						<div className="row p-0 m-0">
							<div className="col-xl-3 col-lg-4 col-md-6  p-0">
								<div
									className="post-image"
									style={{backgroundImage: "url(" + post.image + ")"}}
								></div>
							</div>
							<div className="col-xl-9 col-lg-8 col-md-6 py-4 px-5">
								<div
									className="text-uppercase h5 fw-bold gradient-green-bg gradient-text"
									href={`/news/${post.id}`}
								>
									{post.title.length > 65 ? `${post.title.substring(0, 65)}...` : post.title}
								</div>

								<div className="text-muted text">
									{post.description.length > 200
										? `${post.description.substring(0, 200)}...`
										: post.description}
								</div>

								<div
									className="text-uppercase green-text d-flex justify-content-end align-items-center"
									href={`/news/${post.id}`}
								>
									{t("_continue")} <FiArrowRight className="ms-2" />
								</div>
							</div>
						</div>
					</Link>
				))}
				<nav className="d-flex justify-content-center mt-5 pt-5">
					<ul className="d-flex align-items-center">
						{currentPage > 1 && (
							<div className="net-btn px-2">
								<span className=" " onClick={() => setCurrentPage(currentPage - 1)}>
									<FiChevronsLeft />
								</span>
							</div>
						)}
						{getPageNumbers().map((page, index) =>
							page === "..." ? (
								<span className="text-muted mx-2">...</span>
							) : (
								<button
									key={index}
									className={`mx-2 px-3 py-2 b-radius b-shadow bg-light border ${
										typeof page === "number" && page === currentPage
											? "active green-bg green-shadow text-white"
											: ""
									}`}
									onClick={() => setCurrentPage(page)}
								>
									{page}
								</button>
							)
						)}
						{currentPage < totalPages && (
							<div className="net-btn px-2">
								<span className=" " onClick={() => setCurrentPage(currentPage + 1)}>
									<FiChevronsRight />
								</span>
							</div>
						)}
					</ul>
				</nav>
			</div>
		</div>
	);
}

export default PostsPagination;
