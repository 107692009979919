import React, { useEffect, useState } from 'react'
import { YMaps, Map, Placemark, ZoomControl, FullscreenControl, GeolocationControl} from "react-yandex-maps";

export default function ApplicationMap(props) {
    const[mapState, setMapState] = useState(props?.data[0])
    const[mapZoom, setMapZoom] = useState(props?.data[1])

    useEffect(()=>{
        setMapState(props?.data[0])
        setMapZoom(props?.data[1])
        const target = JSON.parse(localStorage.getItem('target'))
        if(target){
          setMapState(target)
        }
    }, [props])

    const defaultMapstate = {
        center: mapState,
        zoom: mapZoom,
        type: "yandex#map",
        draggable:true, 
      }

  return (
    <YMaps enterprise query={{apikey: 'a829c994-c254-4b3e-8b82-2b577f81b28a'}}>
      <Map width="100%" height="300px" 
        state={defaultMapstate} 
        options={{suppressMapOpenBlock: true }}
        >
        <ZoomControl options={{ float: 'right' }} />
        <GeolocationControl  />
        <FullscreenControl />
        <Placemark
          options={{
            useMapMarginInDragging: true, draggable: true,
            preset: 'islands#darkGreenCircleDotIcon',
          }}
          geometry={mapState}
          preset={'islands#blueDotIcon'}
          onDragEnd={e => localStorage.setItem( "target", JSON.stringify(e.get('target').geometry.getCoordinates()))}
        />
      </Map>
  </YMaps>
  )
}
