import {Link} from "react-router-dom";
import {
	FiMapPin,
	FiChevronRight,
	FiFacebook,
	FiUser,
	FiAtSign,
	FiPhoneCall,
	FiMap,
	FiInstagram,
} from "react-icons/fi";

import useFetch from "../../hooks/useFetch";

import FooterLogoContainer from "./components/FooterLogoContainer";
import FooterLinks from "./components/FooterLinks";
import FooterLinksLegal from "./components/FooterLinksLegal";

export default function Footer(props) {
	const {data} = useFetch(props.url);

	return (
		<footer className="col-sm-12 p-0 footer">
			<div className="sep-line"></div>
			<div className="container pt-5 pb-0 p-3 text-sm-center text-md-start">
				<div className="row mb-5">
					<FooterLogoContainer t={props.t} />
					<FooterLinks t={props.t} />
					<div className="col-xl-4 col-md-6 col-10 mx-auto my-3 my-md-0 locations text-uppercase text-md-start text-center">
						<div className="row d-flex align-items-center">
							{data?.map(addresses => (
								<p key={addresses.id} className="my-1 pb-1">
									<FiMapPin className="me-2 green-text" />
									{addresses.city}
								</p>
							))}
						</div>
					</div>
				</div>
				<hr className="my-3 p-0 " />
				<div className="col-12 py-3">
					<FooterLinksLegal t={props.t} />
				</div>
			</div>

			<div className="footer-bg pt-3 px-5 px-lg-3">
				<div className="container py-4">
					<div className="row">
						<div className="col-lg-6 col-sm-12 p-0 text-lg-start text-md-center text-sm-center mb-3">
							<p className="mb-1">
								© {props.t("_footer_copyright_text_1")} - {new Date().getFullYear()}{" "}
							</p>
							<p className="mb-0">{props.t("_footer_copyright_text_2")}</p>
						</div>
						<div className="col-lg-6 col-sm-12 p-0 text-lg-end text-md-center text-sm-center mb-3">
							<p className="mb-1">{props.t("_footer_copyright_text_3")}</p>
							<p className="mb-0">{props.t("_footer_copyright_text_4")}</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
