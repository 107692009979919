import React from "react";
import {useTranslation} from "react-i18next";
import ImageCard from "../ui-elements/ImageCard";
import {alt_payments} from "../../static/Static";
import arca from "../../assets/images/user_info/arca_logo.png";
import idram from "../../assets/images/user_info/i-dram.jpg";
import telcell from "../../assets/images/user_info/telcell.jpg";
import easypay from "../../assets/images/user_info/easypay.jpg";
import visa from "../../assets/images/user_info/visa_logo.png";
import mastercard from "../../assets/images/user_info/mastercard_logo.png";

export default function PaymentMethods() {
	const {t} = useTranslation();
	return (
		<div className="row mb-3">
			<div className="card b-radius b-shadow mt-4 mb-5 p-5">
				<h4 className="gradient-orange-bg gradient-text text-uppercase pb-2 text-center">{t("_user_page_payment_options")}</h4>
				<div className="col-am-12">
					<div className="row text-muted mt-5 mb-5 justify-content-center mt-3 h6 text-uppercase">
						<ImageCard image={arca} alt={alt_payments} />
						<ImageCard image={idram} alt={alt_payments} />
						<ImageCard image={telcell} alt={alt_payments} />
						<ImageCard image={easypay} alt={alt_payments} />
						<ImageCard image={visa} alt={alt_payments} />
						<ImageCard image={mastercard} alt={alt_payments} />
					</div>
				</div>
			</div>
		</div>
	);
}
