import { Outlet } from "react-router";
import Login from "./pages/user_page/UserLogin";

export const accessToken = localStorage.getItem('token');



const useAuth = () => {
    const user = {logedin: false};
    if(accessToken){
        return user && !user.logedin;
    } 
    return user && user.logedin;
};

export const Protected = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet/> : <Login/>;
};

