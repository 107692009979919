import React from 'react'
import { YMaps, Map, Placemark, ZoomControl, FullscreenControl } from "react-yandex-maps";
import { MapBaloonContent } from '../pages/ui-elements/MapBaloonContent'

export default function CareerMap(props) {

    return (
        <YMaps>
            <Map width="100%" height="350px"
                modules={["templateLayoutFactory", "layout.ImageWithContent"]}
                state={{
                    center: [`${props?.latitude}`, `${props?.longitude}`],
                    zoom: 16,
                    type: "yandex#map",
                    controls: [],
                }}
                instanceRef={ref => {
                    if (ref) {
                        ref.events.add("click", e => {
                            ref.balloon.close();
                        });
                    }
                }}>
                <Placemark geometry={[`${props?.latitude}`, `${props?.longitude}`]}
                    modules={['geoObject.addon.balloon']}
                    options={{
                        hideIconOnBalloonOpen: false,
                        balloonOffset: [0, -17],
                        preset: 'islands#darkGreenCircleDotIcon',
                    }}
                    properties={{
                        balloonContentHeader: `<a target="blank" href=${props?.fullmap} class="green-text">${props?.city}</a>`,
                        balloonContentBody: MapBaloonContent(props.t),
                        balloonContentFooter: `<div class="w-100 text-center mt-3">
                    <a target="blank" href=${props?.fullmap} class="green-text">Բացել քարտեզը</a></div>`,
                    }}
                />
                <ZoomControl />
                <FullscreenControl />
            </Map>
        </YMaps>
    )
}
