import {useTranslation} from "react-i18next";
import MetaTags from "./ui-elements/MetaTags";
import useFetch from "../hooks/useFetch";
import {BASE_URL, notifications} from "../static/api";
import NotifyCard from "./ui-elements/NotifyCard";
import Loader from "./Loader";

export default function Notifications() {
	const {t} = useTranslation();
	const {data, loading} = useFetch(BASE_URL + notifications);
	return (
		<>
			<MetaTags title={"Ծանուցումներ"} />
			<div className="col-12 pt-5 pb-5 p-0">
				<div className="container">
					<div className="pb-5">
						<h3 className="gradient-orange-bg gradient-text text-center text-uppercase">
							{t("_notifications")}
						</h3>
						<h6 className="text-muted text-center pb-5 small">{t("_notifications_description")}</h6>
					</div>

					<div className="b-radius b-shadow p-5">
						{loading ? (
							<Loader />
						) : (
							data?.results?.map(notifications => (
								<NotifyCard
									id={notifications.id}
									message={notifications.message}
									created={notifications.created}
									status={notifications.status}
								/>
							))
						)}
					</div>
				</div>
			</div>
		</>
	);
}
