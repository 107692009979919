import React from 'react'
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectFade } from 'swiper';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

import iptvImage from '../../assets/images/tv_page/iptv.jpg'
import kinoImage from '../../assets/images/tv_page/kinoclub.jpg'
import catchupImage from '../../assets/images/tv_page/catchup.jpg'
import interestsImage from '../../assets/images/tv_page/tv-interests.jpg'
import youtubeImage from '../../assets/images/tv_page/be.png'

const info = [
    {
        id: '01',
        img: iptvImage,
        title: "_iptv_slider_info_title_1",
        desc: "_iptv_slider_info_description_1"
    },
    {
        id: '02',
        img: kinoImage,
        title: "_iptv_slider_info_title_2",
        desc: "_iptv_slider_info_description_2"
    },
    {
        id: '03',
        img: interestsImage,
        title: "_iptv_slider_info_title_3",
        desc: "_iptv_slider_info_description_3"
    },
    {
        id: '04',
        img: catchupImage,
        title: "_iptv_slider_info_title_4",
        desc: "_iptv_slider_info_description_4"
    },
    {
        id: '05',
        img: youtubeImage,
        title: "_iptv_slider_info_title_5",
        desc: "_iptv_slider_info_description_5"
    },
]


export default function TVpageSlider() {
    const { t } = useTranslation();
  return (
    <div className="blog-slider">
        <Swiper
            effect={"fade"}
            centeredSlides={true}
            loop={true}
            autoplay={{
                delay: 4500,
                disableOnInteraction: false,
                }}
            pagination={{
                clickable: true,
                el: ".blog-slider__pagination",
            }}
            className="blog-slider__wrp swiper-wrapper"
            modules={[Pagination, EffectFade, Autoplay]}
        >
            {info.map((info, i) => 
                <SwiperSlide key={i} className="blog-slider__item swiper-slide">
                    <div className="blog-slider__img">
                        <img src={info.img} alt=""/>
                    </div>
                    <div className="blog-slider__content">
                        <div className="blog-slider__title">{t(info.title)}</div>
                        <div className="blog-slider__text">{t(info.desc)}</div>
                    </div>
                </SwiperSlide>
            )}
        </Swiper>
        <div className="blog-slider__pagination"></div>
    </div>
  )
}