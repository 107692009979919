import { useTranslation } from 'react-i18next';
import { FiAlertTriangle, FiCheck } from 'react-icons/fi';

export default function NotifyCard(props) {
    const { t } = useTranslation();

    return (
        <ul className="timeline">
            <li className="timeline-item">
                {props?.status == true ?
                    <span className="timeline-point timeline-point-indicator bg-success"></span> :
                    <span className="timeline-point timeline-point-indicator bg-danger"></span>
                }
                <div className="timeline-event pb-5">
                    <div className="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                        <span className="timeline-event-time">
                            {props?.status == true ?
                                <div className='d-flex justify-content-center'>
                                    <span className='text-success text-uppercase h6 fw-bold'> {t('_notifications_completed')}</span>
                                    <span className='ml-3 text-success'> <FiCheck className='ms-2' /></span>
                                </div>
                                :
                                <div className='d-flex justify-content-center'>
                                    <span className='text-danger text-uppercase h6 fw-bold'> {t('_notifications_in_progress')}</span>
                                    <span className='ml-3 small text-danger'> <FiAlertTriangle className='ms-2' /></span>
                                </div>
                            }
                        </span>
                        <span className="timeline-event-time">{props?.created}</span>
                    </div>
                    <hr className='mt-1 mb-1' />
                    <p className='small text-muted'>{props?.message}</p>
                </div>
            </li>
        </ul>
    )
}
