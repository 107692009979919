import React from 'react'
import { useTranslation } from 'react-i18next';
import { arpicamDescription, arpicamPrice } from '../../static/json'
import MetaTags from '../ui-elements/MetaTags';
import { FiLogIn } from 'react-icons/fi';

import en_lg_arpicam from '../../assets/images/slider/en_lg_arpicam.jpg'
import ru_lg_arpicam from '../../assets/images/slider/ru_lg_arpicam.jpg'
import hy_lg_arpicam from '../../assets/images/slider/hy_lg_arpicam.jpg'
import en_sm_arpicam from '../../assets/images/slider/en_sm_arpicam.jpg'
import ru_sm_arpicam from '../../assets/images/slider/ru_sm_arpicam.jpg'
import hy_sm_arpicam from '../../assets/images/slider/hy_sm_arpicam.jpg'



export default function ArpicamPage() {
    const { t } = useTranslation();
    const lng = localStorage.getItem('i18nextLng')
    return (
        <>
            <MetaTags
                title={'ArpiCam անվտանգության Ձեր Գործակալը'}
                desc={'ArpiCam տեսահսկման նոր ծառայությունը հեղաշրջում է կատարում այս ոլորտում՝ ազատելով Ձեզ բոլոր ծախսերից և քաշքշուքներից։'}
                link={'https://fonts.googleapis.com/css?family=Rubik:400'}
            />

            <div className="col-12 p-0">
                <div className="container pb-0">
                    <img className="hidden-sm hidden-xs" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_lg_arpicam : hy_lg_arpicam) : en_lg_arpicam
                        } />
                    <img className="hidden-lg hidden-md" alt={"Arpinet LLC"}
                        src={
                            lng !== 'en-US' ? (lng == 'ru-RU' ? ru_sm_arpicam : hy_sm_arpicam) : en_sm_arpicam
                        } />
                </div>
                <div className="sep-line mt-1"></div>
            </div>

            <div className="col-xs-12 p-0 pt-5 pb-5">
                <div className="container pb-5">
                    <div className="col-12 text-end mb-4">
                        <a className="net-btn green-bg green-shadow py-3 px-4 m-2" target="blank" href="https://watcher.arpinet.am/">{t('_arpicam_login')}<FiLogIn className='ms-2' /> </a>
                    </div>

                    <div className="col-12 pt-2 pb-5 text text-justify">
                        <span className="gradient-green-bg gradient-text fw-bold">ArpiCam</span> {t('_arpicam_description_line_1')}
                        <br /> {t('_arpicam_description_line_2')}
                    </div>

                    <div className="col-12 pb-5 text-center mt-3 mb-3">
                        <div className="gradient-orange-bg gradient-text h1 pb-2">{t('_arpicam_reason_title_headline')}</div>
                        <div className="gradient-green-bg gradient-text h4">{t('_arpicam_reason_title_subline')}</div>
                    </div>

                    <div className="col-12 pt-2 pb-5 mb-5">
                        {arpicamDescription.map((d, i) =>
                            <a key={i} href='#' className="col-12 card b-radius b-shadow border-0 p-3 mb-4">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-3 col-12 mx-auto d-flex align-items-center justify-content-center">
                                        <img className='py-sm-3' src={d.img} alt='Arpicam' style={{ width: '100px' }} />
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-sm-12 col-12 text-md-start text-lg-start text-sm-center text pt-3 p-5">
                                        <h5 className="gradient-orange-bg gradient-text">{t(d.title)}</h5>
                                        <hr />
                                        <p className="mt-0 pt-0">{t(d.desc)}</p>
                                    </div>
                                </div>
                            </a>
                        )}
                    </div>
                </div>
                <div className="sep-line"></div>
            </div>



            <div className="col-12 pt-5 mb-5 pb-5">
                <div className="container p-0">
                    <div className="row">

                        {arpicamPrice.map((p, i) =>
                            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-10 mx-auto mb-3">
                                <a href="#" className="card b-radius b-shadow border-0 pt-3 pb-5 pl-2 pr-2 mb-3">
                                    <div className="col-12 px-5 justify-content-center">
                                        <div className="d-flex align-items-center text-muted">
                                            <div className="col-3 p-2"><img src={p.img} alt='Arpicam' /></div>
                                            <div className="col-9 p-2 mt-2 fs-5 fw-bold">{t(p.title)}</div>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <span className="divider mb-4"></span>
                                        <div className="display-1 gradient-orange-bg gradient-text fw-bold rubik mb-0 pb-0">{p.year_1_price}</div>
                                        <div className="text-muted fw-bold mb-2">{t(p.year_1_desc)}</div>
                                    </div>
                                    <div className="col-12 text-center pb-4">
                                        <span className="divider mb-4"></span>
                                        <div className="display-1 gradient-orange-bg gradient-text fw-bold rubik mb-0 pb-0">{p.year_2_price}</div>
                                        <div className="text-muted fw-bold mb-2">{t(p.year_2_desc)}</div>
                                    </div>
                                </a>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}
