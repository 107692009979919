import React from 'react'
import {EmailIcon, FacebookIcon, FacebookMessengerIcon, LinkedinIcon, MailruIcon, OKIcon, TelegramIcon, TwitterIcon, ViberIcon, VKIcon, WhatsappIcon, EmailShareButton, FacebookShareButton, LinkedinShareButton, MailruShareButton, OKShareButton, TelegramShareButton, TwitterShareButton, ViberShareButton, VKShareButton, WhatsappShareButton, FacebookMessengerShareButton} from "react-share";

export default function SocialSaheIcons(props) {
  return (
    <div className='ya-share my-3'>
        <FacebookShareButton url={props?.url} quote={props?.description} className="m-2">
            <FacebookIcon size={32} round={true} className="share-btn" />
        </FacebookShareButton>
        <TwitterShareButton url={props?.url} title={props?.description} className="m-2">
            <TwitterIcon size={32} round={true} className="share-btn" />
        </TwitterShareButton>
        <LinkedinShareButton url={props?.url} summary={props?.description} source={props?.url} className="m-2">
            <LinkedinIcon size={32} round={true} className="share-btn" />
        </LinkedinShareButton>
        <FacebookMessengerShareButton url={props?.url} appId="829517433891421" className="m-2">
            <FacebookMessengerIcon size={32} round={true} className="share-btn" />
        </FacebookMessengerShareButton>
        <TelegramShareButton url={props?.url} title={props?.description} className="m-2">
            <TelegramIcon size={32} round={true} className="share-btn" />
        </TelegramShareButton>
        <ViberShareButton url={props?.url} title={props?.description} className="m-2">
            <ViberIcon size={32} round={true} className="share-btn" />
        </ViberShareButton>
        <WhatsappShareButton url={props?.url} title={props?.description} className="m-2">
            <WhatsappIcon size={32} round={true} className="share-btn" />
        </WhatsappShareButton>
        <MailruShareButton url={props?.url} title={props?.title} description={props?.description} imageUrl={props?.image} className="m-2">
            <MailruIcon size={32} round={true} className="share-btn" />
        </MailruShareButton>
        <OKShareButton url={props?.url} title={props?.title} description={props?.description} image={props?.image} className="m-2">
            <OKIcon size={32} round={true} className="share-btn" />
        </OKShareButton>
        <VKShareButton url={props?.url} title={props?.description} image={props?.image} className="m-2">
            <VKIcon size={32} round={true} className="share-btn" />
        </VKShareButton>
        <EmailShareButton url={props?.url} subject={props?.title} body={props?.description} className="m-2">
            <EmailIcon size={32} round={true} className="share-btn" />
        </EmailShareButton>
    </div>
  )
}
