import React, {useState, useEffect} from "react";
import axios from "axios";
import {BASE_URL, USER_PAYMENTS_HISTORY} from "../../static/api";
import {TbCurrencyDram} from "react-icons/tb";
import {LiaFileContractSolid} from "react-icons/lia";
import {LuCalendarCheck} from "react-icons/lu";
import {FiChevronsRight, FiChevronsLeft} from "react-icons/fi";
import {useTranslation} from "react-i18next";

export default function UserPaymentHistory(props) {
	const {t} = useTranslation();
	const [errorText, setErrorText] = useState();
	const [payments, setPayments] = useState([]);
	const [totalPages, setTotalPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		loadData();
	}, [currentPage]);

	function loadData() {
		axios
			.get(
				BASE_URL +
					USER_PAYMENTS_HISTORY +
					`?agrm_id=` +
					localStorage.getItem("key") +
					`&pg_size=10&pg_num=` +
					currentPage
			)
			.then(res => {
				if (res.status === 400) {
					setErrorText("Error: Bad Request");
				} else {
					setPayments(res.data.payments);
					setTotalPages(res.data.total_count);
				}
			})
			.catch(error => {
				if (error.response && error.response.status === 400) {
					setErrorText("Error: Bad Request");
				} else {
					setErrorText("An error occurred");
				}
			});
	}

	const paginationPages = Math.ceil(totalPages / 10);

	const getPageNumbers = () => {
		const pageNumbers = [];
		let startPage, endPage;
		if (paginationPages <= 4) {
			startPage = 1;
			endPage = paginationPages;
		} else {
			if (currentPage <= 4) {
				startPage = 1;
				endPage = 4;
			} else if (currentPage + 2 >= paginationPages) {
				startPage = paginationPages - 5;
				endPage = paginationPages;
			} else {
				startPage = currentPage - 1;
				endPage = currentPage + 1;
			}
		}
		if (startPage > 1) {
			pageNumbers.push(1, "...");
		}
		for (let i = startPage; i <= endPage; i++) {
			pageNumbers.push(i);
		}
		if (endPage < paginationPages) {
			pageNumbers.push("...", paginationPages);
		}
		return pageNumbers;
	};

	return (
		<>
			<div className="container p-0">
				<div className="card w-100 b-radius b-shadow p-4 border-0 ">
					<h2 className="text-uppercase mb-4">{t("_user_page_online_payment_history_title")}</h2>
					<div class="table-responsive">
						<table class="table">
							<thead>
								<tr>
									<th scope="col">{t("_user_page_online_payment_history_type")}</th>
									<th scope="col">{t("_user_page_online_payment_history_ammount")}</th>
									<th scope="col">{t("_user_page_online_payment_history_date")}</th>
								</tr>
							</thead>
							<tbody>
								{payments.map((payment, index) => (
									<tr className="">
										<td>
											<div className="d-flex align-items-center my-2 ">
												<LiaFileContractSolid className="me-2" />
												{payment.class_name}
											</div>
										</td>
										<td>
											<div className="d-flex align-items-center my-2">
												<span className="lh-0">{payment.amount}</span>{" "}
												<TbCurrencyDram className="lh-0 ms-2 small" />
											</div>
										</td>
										<td>
											<div className="d-flex align-items-center my-2">
												<LuCalendarCheck className="lh-0 me-2" />
												<span className="lh-0">{payment.pay_date}</span>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<nav className="d-flex justify-content-center  pt-5">
					<ul className="d-flex align-items-center">
						{currentPage > 1 && (
							<div className="net-btn px-2">
								<span className=" " onClick={() => setCurrentPage(currentPage - 1)}>
									<FiChevronsLeft />
								</span>
							</div>
						)}
						{getPageNumbers().map((page, index) =>
							page === "..." ? (
								<span className="text-muted mx-2">...</span>
							) : (
								<button
									key={index}
									className={`mx-2 px-3 py-2 b-radius b-shadow bg-light border ${
										typeof page === "number" && page === currentPage
											? "active green-bg green-shadow text-white"
											: ""
									}`}
									onClick={() => setCurrentPage(page)}
								>
									{page}
								</button>
							)
						)}
						{currentPage < totalPages && (
							<div className="net-btn px-2">
								<span className=" " onClick={() => setCurrentPage(currentPage + 1)}>
									<FiChevronsRight />
								</span>
							</div>
						)}
					</ul>
				</nav>
			</div>
		</>
	);
}
