export const BASE_URL = `https://api.arpinet.am`;
export const sliderURL = `/api/slider/`;
export const POSTS_URL = `/api/posts/`;
export const itemURL = `/api/posts/`;
export const faqURL = `/api/faqs/?`;
export const aboutURL = `/api/about/`;
export const reportsURL = `/api/reports/`;
export const termsURL = `/api/terms/`;
export const adressessURL = `/api/addresses/`;
export const cvURL = `/api/resume/`;
export const tariffsURL = `/api/tariffs/`;
export const locationsURL = `/api/locations/`;
export const callRequestURL = `/api/posts/callback/`;
export const freeFormUrl = `/api/posts/info/`;
export const identify = `/api/ident_verify/`;
export const identify_confirm = `/api/ident_verify_confirm/`;
export const restart_data = `/api/user/restart_data/`;
export const notifications = `/api/notifications/`;
export const feedback = `/api/feedback/create/`;
export const ratingURL = `/api/rate/create/`;
export const careerURL = `/api/career/`;

// user
export const uidURL = `/api/check_user_uid/`;
export const signinURL = `/api/sign_in/`;
export const signinConfirmURL = `/api/sign_in_confirm/`;
export const USER_DATA = `/api/user/data/`;
export const USER_PAYMEN = `/billing/add/payment/`;
export const USER_PAYMENTS_HISTORY = `/billing/get/payments/`;

// user
export const VG_URL = `/api/user/device/update/`;
