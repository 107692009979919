import React from "react";

export default function ImageCard(props) {
	return (
		<div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-4 p-2">
			<div className="bg-white p-3 b-radius b-shadow">
				<img src={props.image} alt={props.alt} />
			</div>
		</div>
	);
}
