import React from "react";
import { useTranslation } from 'react-i18next';
import { FiArrowRight  } from 'react-icons/fi';
import { Link } from "react-router-dom";


export default function PrivacyAndPolicy() {
    const { t } = useTranslation();
  return (
    <div className="col-xs-12 p-0 position-relative">
      <div className="container py-4 p-0">
        <div className="fs-4 bold">
            Privacy Statement
        </div>
        <p>
            This Privacy Statement explains our practices regarding the collection, use, and disclosure of certain information, including your personal information, by Arpinet LLC. Contacting Us
        </p>
        <p>
            For any questions concerning your account, or concerning this Privacy Statement, or our use of your personal information, cookies or similar technologies, please contact us via email at info@arpinet.tv.
        </p>
        <p>
            Alternatively, you can write to us by postal mail at Arpinet LLC, 22/2 Baghramyan str., Ejmiatsin, 1101, Armenia.
        </p>
        <p>
            The data controller of your personal information is Arpinet LLC, which also provides the service to you (address above). Also, please note that if you contact us to assist you, for your safety and ours we may need to authenticate your identity before fulfilling your request.
            Collection of Information
        </p>
        <p>
            We receive and store information about you such as:
        </p>
        <p>
            &#8226; Information you provide us: We collect information you provide to us which includes:
        </p>
        <p>
        	&#8226; information collected when you choose to provide reviews or ratings, taste preferences, account settings, set preferences in Your Account or otherwise provide information to us through our service or elsewhere.
        </p>
        <p>
            &#8226; Information we collect automatically: We collect information regarding you and your use of our service, your interactions with us and our advertising, as well as information regarding your computer or other device used to access our service (such as smart TVs, mobile devices, and set top boxes). This information includes:
        </p>
        <p>
            &#8226; your activity on the Arpinet TV service, such as title selections, watch history and search queries;
        </p>
        <p>
            &#8226; details regarding your interactions with customer service, such as the date, time and reason for contacting us, transcripts of any chat conversations, and if you call us, your phone number;
        </p>
        <p>
            &#8226; device IDs or unique identifiers, device and software characteristics (such as type and configuration), connection information, statistics on page views, referral URLs, ad data, IP address and standard web log information;
        </p>
        <p>
            &#8226; information collected via the use of cookies, web beacons and other technologies. See our Cookies and Internet Advertising section for more details.

        </p>
        <p>
            &#8226; Information from other sources: We might supplement the information described above with information we obtain from other sources, including from both online and offline data providers. Such supplemental information could include demographic data, interest based data, and Internet browsing behavior.
        </p>
        <p>
            Use of Information
        </p>
        <p>
            We use the informastion we collect to provide, analyze, administer, enhance and personalize our services and marketing efforts, to process your registration, your orders and your payments, and to communicate with you on these and other topics. For example, we use the information we collect for:
        </p>
        <p>
            &#8226; determining your general geographic location, providing localized content, providing you with customized and personalized viewing recommendations for movies and TV shows we think will be enjoyable, determining your Internet service provider and helping us quickly and efficiently respond to inquiries and requests;
        </p>
        <p>
            &#8226; preventing, detecting and investigating potentially prohibited or illegal activities, including fraud, and enforcing our terms (such as determining free trial eligibility);
        </p>
        <p>
            &#8226; analyzing and understanding our audience; improving our service (including our user interface experiences), delivery optimization, content selection, and recommendation algorithms.
        </p>
        <p>
            &#8226; communicating with you concerning our service (for example by email, push notifications and text messaging), so that we can send you news about Arpinet TV, details about new features and content available on Arpinet TV, and special offers, promotional announcements and consumer surveys, and to assist you with operational requests such as password reset requests. Please see the "Your Choices" section of this Privacy Statement to learn how to set or change your communications preferences.
        </p>
        <p>
            Disclosure of Information
        </p>
        <p>
            We disclose your information for certain purposes and to third parties, as described below:
        </p>
        <p>
            &#8226; Protection of Arpinet TV and others: Arpinet TV and its Service Providers may disclose and otherwise use your personal and other information where we or they reasonably believe such disclosure is needed to (a) satisfy any applicable law, regulation, legal process, or governmental request, (b) enforce applicable terms of use, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address illegal or suspected illegal activities (including payment fraud), security or technical issues, or (d) protect against harm to the r0ights, property or safety of Arpinet TV, its users or the public, as required or permitted by law.
        </p>
        <p>
            &#8226; Business transfers: In connection with any reorganization, restructuring, merger or sale, or other transfer of assets, we will transfer information, including personal information, provided that the receiving party agrees to respect your personal information in a manner that is consistent with our Privacy Statement.
        </p>
        <p>
            You may also choose to disclose your information in the following ways:
        </p>
        <p>
            &#8226; While using the Arpinet TV service you will have opportunities to post reviews or other information publicly, and third parties could use the information you disclose;
        </p>
        <p>
            &#8226; Social plugins (including those offered by Facebook, Twitter, Pinterest, and Google) allow you to share information on those platforms.
        </p>
        <p>
            Social plugins and social applications are operated by the social network themselves, and are subject to their terms of use and privacy policies.
        </p>
        <p>
            If you share or otherwise allow others to have access to your account, they will be able to see your information, including in some cases personal information, such as your watch history, ratings, reviews and account information (including your email address or other information in Your Account). This remains true even if you use our profiles feature.
        </p>
        <p>
            Your Choices
        </p>
        <p>
            If you no longer want to receive certain communications from us via email, simply access the "Email preferences" link on the "Your Account" page of our website and uncheck those items to unsubscribe, or click the "unsubscribe" link in the email. Please note that you cannot unsubscribe from certain correspondence from us, such as messages relating to your account transactions.
        </p>
        <p>
            To exercise choices regarding cookies set through our website, as well as other types of online tracking and Internet advertising, see the Cookies and Internet Advertising section (below).
        </p>
        <p>
            Your Rights
        </p>
        <p>
            You can request access to your personal information, or correct or update out-of-date or inaccurate personal information we hold about you. You can most easily do this by visiting the "Your Account" portion of our website, where you have the ability to access and update a broad range of information about your account, including your contact information, your Arpinet TV payment information, and various related information about your account (such as your taste preferences, the content you have rated and your reviews). You must be signed in to access "Your Account." Please also see the Your Choices section of this Privacy Statement for additional choices regarding your information.
            You may also ask us to delete personal information that we hold about you if it is no longer needed to provide the service to you, for our billing or records purposes, or for other lawful reasons. To do this, or if you have any other question regarding our privacy practices, please contact us at info@arpinet.tv.
        </p>
        <p>
            Security
        </p>
        <p>
            We believe we use reasonable administrative, logical, physical and managerial measures to safeguard your personal information against loss, theft and unauthorized access, use and modification. Unfortunately, no measures can be guaranteed to provide 100% security. Accordingly, we cannot guarantee the security of your information.
        </p>
        <p>
            Other Websites, Platforms and Applications
        </p>
        <p>
            Children
        </p>
        <p>
            There is no age restriction to use Arpinet TV services once subscribed, but you must be 18 years of age or older to subscribe to the Arpinet TV service. In certain jurisdictions, the age of majority may be older than 18, in which case, you must satisfy that age in order to become a member. While individuals under the age of 18 may utilize the service, they may do so only with the involvement, supervision, and approval of a parent or legal guardian.
        </p>
        <p>
            Changes to This Privacy Statement
        </p>
        <p>
            We will update this Privacy Statement from time to time in response to changing legal, regulatory or operational requirements. We will provide notice of any such changes (including when they will take effect) in accordance with law. Your continued use of the Arpinet TV service after any such updates take effect will constitute acceptance of those changes. If you do not accept any updates to this Privacy Statement, you may cancel your use of the Arpinet TV service. To see when this Privacy Statement was last updated, please see the "Last Updated" section below.
        </p>
        <p>
            We use cookies and other technologies to make it easy to access our services by remembering you when you return, to provide and analyze our services, to learn more about our users and their likely interests, and to deliver and tailor marketing or advertising. We want you to be informed about our use of these technologies, so this notice explains the types of technologies we use, what they do and your choices regarding their use.
        </p>
        <p>
            1. What are cookies?
        </p>
        <p>
            Cookies are small data files that are commonly stored on your device when you browse and use websites and online services. They are widely used to make websites work, or to work more efficiently. Cookies are not the only types of technology that enable this functionality; we also use other, similar types of technologies. See below for more information and examples.
        </p>
        <p>
            2. Why does Arpinet TV use cookies?
        </p>
        <p>
            We and our Service Providers may use the following types of cookies:
        </p>
        <p>
            &#8226; Essential cookies: These cookies are strictly necessary to provide our website or online service. For example, we or our Service Providers may use these cookies to authenticate and identify our members when they use our websites and applications so we can provide our service to them. They also help us to enforce our Terms of Use, prevent fraud and maintain the security of our service.
        </p>
        <p>
            &#8226; Performance and functionality cookies: These cookies are not essential, but help us to personalize and enhance your online experience with Arpinet TV. For example, they help us to remember your preferences and prevent you from needing to re-enter information you previously provided (for example, during member sign up). We also use these cookies to collect information (such as popular pages, conversion rates, viewing patterns, click-through and other information) about our visitors' use of the Arpinet TV service so that we can enhance and personalize our website and service and conduct market research. Deletion of these types of cookies will result in limited functionality of our service.
        </p>
        <p>
            &#8226; Advertising cookies: These cookies use information about your visit to this and other websites, such as the pages you visit, your use of our service or your response to ads and emails, to deliver ads that are more relevant to you. These types of ads are called "Interest-Based Advertising." Many of the advertising cookies associated with our service belong to our Service Providers.
        </p>
        <p>
            3. How can I exercise choice regarding cookies and other types of online tracking?
        </p>
        <p>
            For more information about cookies set through our website, as well as other types of online tracking (including the collection of information by third parties about your online activities over time and across third-party Web sites or online services for online interest based advertising), and to exercise choices regarding them, send us an email to info@arpinet.tv. At this time, we do not respond to Web browser "do not track" signals.
        </p>
        <p>
            4. How Does Arpinet TV use Web Beacons and other Technologies?
        </p>
        <p>
            Web beacons (also known as clear gifs or pixel tags) often work in conjunction with cookies. We and our Service Providers may use them for similar purposes as cookies, such as to understand and enhance the use of our service, improve site performance, monitor visitor traffic and actions on our site, and understand interactions with our marketing (including email and online ads on third party sites). Because web beacons often work in conjunction with cookies, in many cases, declining cookies will impair the effectiveness of web beacons.
        </p>
        <p>
            We use other technologies that are similar to cookies, such as browser storage and plugins (e.g., HTML5, IndexedDB, and WebSQL). Like cookies, some of these technologies may store small amounts of data on your device. We may use these and various other technologies for similar purposes as cookies, such as to enforce our terms, prevent fraud, and analyze the use of our service. There are a number of ways to exercise choice regarding these technologies. For example, many popular browsers provide the ability to clear browser storage, commonly in the settings or preferences area; see your browser’s help function or support area to learn more. Other technologies, such as Silverlight storage, may be cleared from within the application.
        </p>
      </div>
    </div>
  );
}
