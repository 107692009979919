export const MapBaloonContent = (props) => {
    return (
        `<div class="d-grid text-center">
         <div class="text-muted fst-italic">${props('_service_centers_working_hours')}</div>
         <div class="text-muted fst-italic">${props('_service_centers_working_days_mon')} 09:00 - 18:00</div>
         <div class="text-muted fst-italic">${props('_service_centers_working_days_tue')} 09:00 - 18:00</div>
         <div class="text-muted fst-italic">${props('_service_centers_working_days_wed')} 09:00 - 18:00</div>
         <div class="text-muted fst-italic">${props('_service_centers_working_days_thu')} 09:00 - 18:00</div>
         <div class="text-muted fst-italic">${props('_service_centers_working_days_fri')} 09:00 - 18:00</div>
         <div class="text-muted fst-italic">${props('_service_centers_working_days_sat')} <span class="text-danger">${props('_service_centers_non_working_day')}</span></div>
         <div class="text-muted fst-italic">${props('_service_centers_working_days_sun')} <span class="text-danger">${props('_service_centers_non_working_day')}</span></div>
        </div>`
    );
};


