import React, {useState, useEffect} from "react";
import axios from "axios";
import {BASE_URL, USER_PAYMEN} from "../../../static/api";
import {InputNumber} from "primereact/inputnumber";
import Loader from "../../Loader";

export default function PaymentForm(props) {
	const [value, setValue] = useState(42723);
	const [totalRent, setTotalRent] = useState(0);
	const [amountDue, setAmountDue] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (props.data && props.data.agreements && props.data.agreements.tariffs) {
			const tariffs = props.data.agreements.tariffs;
			let total = 0;
			if (tariffs.length > 1) {
				total = tariffs.reduce((sum, tariff) => sum + tariff.rent, 0);
			} else if (tariffs.length === 1) {
				total = tariffs[0].rent;
			}
			setTotalRent(total);

			const balance = props.data.agreements.balance || 0;
			const due = total - balance;
			setAmountDue(due);
			setValue(due);
		}
	}, [props]);

	function sendData(e) {
		let lang = localStorage.getItem("i18nextLng").substring(0, 2);
		if (lang == "am") {
			lang = "hy";
		}

		localStorage.setItem("alert", true);
		localStorage.setItem("paymentIsCompleted", false);
		e.preventDefault();
		axios({
			method: "POST",
			url: BASE_URL + USER_PAYMEN,
			data: {
				agrm_id: props.data.agreements.agrm_id,
				amount: value,
				lang,
				agrm_name: props.data.agreements.agrm_num,
			},
		}).then(res => {
			setLoading(true);
			setTimeout(() => {
				window.open(res.data.redirect_url, "_blank");
			}, 1500);
		});
	}

	return (
		<form className="text-center col-10 mx-auto" onSubmit={e => e.preventDefault()}>
			{props?.t("_user_page_online_payment_form_seperated_lines_01")}
			<span className="mx-2 text-danger fst-italic fw-bold text-decoration-underline">
				{props.data.agreements.agrm_num}
			</span>
			{props?.t("_user_page_online_payment_form_seperated_lines_02")}
			<span className="mx-2 text-danger fw-bold fst-italic">{Math.round(totalRent)}</span>
			{props?.t("_user_page_online_payment_form_seperated_lines_03")}
			<span className="mx-2 text-danger fw-bold fst-italic">
				{Math.round(props.data.agreements.balance)}
			</span>
			{props?.t("_user_page_online_payment_form_seperated_lines_04")}
			<span className="mx-2 text-success fw-bold fst-italic">{Math.round(amountDue)}</span>
			{props?.t("_user_page_online_payment_form_seperated_lines_05")}
			<div className="col-lg-6 col-12 mx-auto my-5">
				<InputNumber
					suffix=" ֏"
					min={100}
					max={10000000}
					className="w-100 b-radius b-shadow"
					value={Math.round(amountDue)}
					onValueChange={e => setValue(e.value)}
				/>
				<label className="text-danger small mt-2">
					{props?.t("_user_page_online_payment_form_minimum_amount")} ֏
				</label>
			</div>
			<div className="col-12 text-center">
				{loading ? (
					<Loader />
				) : (
					<button
						className="net-btn green-bg green-shadow py-3 px-4 m-2"
						disabled={!value}
						onClick={value ? sendData : null}
					>
						{props?.t("_user_page_online_payment_btn")}
					</button>
				)}
			</div>
		</form>
	);
}
