import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import axios from "axios";
import {BASE_URL, careerURL, adressessURL} from "../static/api";
import {FiActivity, FiMapPin} from "react-icons/fi";
import {FaShareAlt} from "react-icons/fa";
import CareerMap from "./CareerMap";
import SocialSaheIcons from "./ui-elements/SocialSaheIcons";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function CareerItem() {
	const {id} = useParams();
	const lng = localStorage.getItem("i18nextLng");
	const {t} = useTranslation();
	const [addresses, setAddreses] = useState();
	const [content, setContent] = useState();

	useEffect(() => {
		axios
			.get(BASE_URL + careerURL + "?id=" + id, {headers: {"Accept-Language": lng.substring(0, 2)}})
			.then(res => {
				setContent(res.data[0]);
			});
		axios
			.get(BASE_URL + adressessURL, {headers: {"Accept-Language": lng.substring(0, 2)}})
			.then(res => {
				setAddreses(res.data);
			});
	}, [lng]);

	return (
		<div className="col-12 float-left p-0 py-5">
			{content ? (
				<Helmet>
					<meta property="og:image" content={content?.image}></meta>
					<meta property="og:site_name" content={content?.position}></meta>
					<meta property="og:type" content="article"></meta>
					<meta property="og:url" content={window.location.href}></meta>
					<meta property="og:description" content={content?.cariere_desc.description}></meta>
				</Helmet>
			) : null}

			<div className="container pb-5">
				<div className="card b-radius b-shadow border-0 mb-5 p-5 text">
					<h3 className="gradient-orange-bg gradient-text text-uppercase pb-2">
						{content?.position}
					</h3>
					<p className="text-muted d-flex align-items-center">
						<FiActivity className="me-2" />
						{content?.start} / {content?.end}
					</p>
					<p className="text-muted d-flex align-items-center">
						<FiMapPin className="me-2" />
						{content?.location}
					</p>
					{addresses?.map(address =>
						address.city == content?.location ? (
							<div className="col-12 b-radius b-shadow border-0 overflow-hidden p-0 mt-1 mb-4">
								<CareerMap
									t={t}
									latitude={address.latitude}
									longitude={address.longitude}
									fullmap={address.fullmap}
									city={address.city}
								/>
							</div>
						) : null
					)}
					{content?.cariere_desc.map((desc, i) => (
						<div className="col-12 p-0 my-3">
							<h3 className="gradient-orange-bg gradient-text text-uppercase pb-2">{desc.title}</h3>
							<p className="text text-muted">{desc.description}</p>
						</div>
					))}

					<div className="share col-12 pt-5 text-center">
						<span className="gradient-green-bg gradient-text">
							<FaShareAlt className="me-2" />
							ՏԱՐԱԾԵԼ
						</span>
						<SocialSaheIcons
							url={window.location.href}
							title={content?.position}
							description={content?.position}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
