import React, { useEffect, useState } from 'react'
import ApplicationRequest from '../components/ApplicationRequest'
import { FaInternetExplorer, FaDesktop } from 'react-icons/fa';
import { FiMonitor, FiWifi, FiCheckSquare, FiX } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

export default function TariffTVComponent(props) {
    const { t } = useTranslation();

    const [load, setLoad] = useState(null)
    useEffect(() => {
        if (load == null) {
            setLoad(true)
        }
    }, [])

    return (
        <>
            {props?.data?.map((tarif) =>
                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 stretch-card" key={tarif.id}>
                    <div className="card w-100 d-flex b-radius b-shadow tv border-0 p-2 pt-4 pb-4 m-1 mb-3 ">
                        <h2 className="text-center mb-0 col-12">{tarif.iptv ? tarif.name : null}</h2>
                        <h1 className="w-100 text-center">{tarif.price}</h1>

                        <div className="w-75 mx-auto pt-3 pb-2 border-bottom d-flex justify-content-center align-items-center">
                            <h1 className='col-3 text-center d-flex align-items-center'>
                                <FaInternetExplorer className="green-text h3 m-0 me-3" />
                            </h1>
                            <h2 className='col-3 text-center d-flex justify-content-end  align-items-center'>
                                {tarif.speed}
                            </h2>
                            <ul className="col-6 till-wrapper pt-1 text-start">
                                <li>{t('_tariff_till')} </li>
                                <li>{t('_tariff_speed')}</li>
                            </ul>
                        </div>


                        <div className="w-75 mx-auto pt-3 pb-2 border-bottom d-flex justify-content-center align-items-center">
                            <h1 className='col-3 text-center d-flex align-items-center'>
                                <FiMonitor className="green-text h3 m-0 me-3" />
                            </h1>
                            <h2 className='col-3 text-center d-flex justify-content-end align-items-center'>
                                {tarif.channels}
                            </h2>
                            <ul className="col-6 till-wrapper pt-1 text-start">
                                <li>{t('_tariff_digital')} </li>
                                <li>{t('_tariff_tv_channels')}</li>
                            </ul>
                        </div>

                        {tarif.catv ?
                            <div className="w-75 mx-auto pt-3 pb-2 border-bottom d-flex justify-content-center align-items-center">
                                <h1 className='col-3 text-center d-flex align-items-center'>
                                    <FaDesktop className="green-text h3 m-0 me-3" />
                                </h1>
                                <h2 className='col-3 text-center d-flex justify-content-end align-items-center'>
                                    {tarif.catv_count}
                                </h2>
                                <ul className="col-6 till-wrapper pt-1 text-start">
                                    <li>{t('_tariff_tv_connection_opportunity')}</li>
                                </ul>
                            </div>
                            : null}

                        <div className="w-75 mx-auto pt-3 pb-2 border-bottom d-flex justify-content-center align-items-center">
                            <h1 className='col-3 text-center d-flex align-items-center'>
                                <FiWifi className="green-text h3 m-0 me-3" />
                            </h1>
                            <ul className="col-9 till-wrapper pt-1 text-start">
                                <li>WIFI </li>
                                <li>{t('_tariff_activation')}</li>
                            </ul>
                        </div>

                        {tarif.iptv ?
                            <div className="w-75 mx-auto pt-3 pb-2 border-bottom d-flex justify-content-center align-items-center">
                                <h1 className='col-3 text-center d-flex align-items-center'>
                                    <FiCheckSquare className="green-text h3 m-0 me-3" />
                                </h1>
                                <ul className="col-9 till-wrapper pt-1 text-start">
                                    <li>{t('_tariff_catch_up')} </li>
                                    <li>* Catch-UP!</li>
                                </ul>
                            </div> :
                            <div className="w-75 mx-auto pt-3 pb-2 border-bottom d-flex justify-content-center align-items-center">
                                <h1 className='col-3 text-center d-flex align-items-center'>
                                    <FiX className="green-text h3 m-0 me-3" />
                                </h1>
                                <ul className="col-9 till-wrapper pt-1 text-start">
                                    <li>{t('_tariff_catch_up_')} </li>
                                    <li>{t('_tariff_catch_up_not_avaible')}</li>
                                </ul>
                            </div>
                        }
                        <div className="col-sm-12 p-0 mt-4 text-center">
                            <ApplicationRequest data={tarif} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}