import React, {useState, useEffect} from "react";
import useFetch from "../../hooks/useFetch";
import {FiMapPin} from "react-icons/fi";
import {BASE_URL, adressessURL} from "../../static/api";
import {
	YMaps,
	Map,
	Placemark,
	ZoomControl,
	FullscreenControl,
	GeolocationControl,
} from "react-yandex-maps";
import {useTranslation} from "react-i18next";
import {MapBaloonContent} from "../../pages/ui-elements/MapBaloonContent";

export default function ServiceCenters(props) {
	const {t} = useTranslation();

	const {data} = useFetch(BASE_URL + adressessURL);

	const [map, setMap] = useState({
		zoom: 9,
		centerLatitude: 40.204369,
		centerLongitute: 44.503011,
		template: "",
	});
	const [state, setState] = useState({
		centers: [],
	});

	const centerslist = data?.map(pin => (
		<a
			href="#"
			onClick={prevState => {
				Object.assign({}, prevState);
				setMap({
					centerLatitude: pin.latitude,
					centerLongitute: pin.longitude,
					zoom: 17,
				});
			}}
			className="w-auto b-radius b-shadow border-0 small py-3 px-4 m-2 text-muted bg-white d-flex align-items-center"
			key={pin.id}
		>
			<FiMapPin className="green-text me-2" />
			<span className="">{pin.city}</span>
		</a>
	));

	const locations = data?.map((p, i) => (
		<Placemark
			key={i}
			geometry={[`${p.latitude}`, `${p.longitude}`]}
			modules={["geoObject.addon.balloon"]}
			options={{
				hideIconOnBalloonOpen: false,
				balloonOffset: [0, -17],
				preset: "islands#darkGreenCircleDotIcon",
			}}
			properties={{
				balloonContentHeader: `<a target="blank" href=${p.fullmap} class="green-text">${t(
					p.city
				)}</a>`,
				balloonContentBody: MapBaloonContent(t),
				balloonContentFooter: `<div class="w-100 text-center mt-3">
          <a target="blank" href=${p.fullmap} class="green-text">${t(
					"_service_centers_open_map"
				)}</a></div>`,
			}}
		/>
	));

	return (
		<div className="col-12 p-0 pt-3 pb-3">
			<div className="container pb-5">
				<h3 className="gradient-orange-bg gradient-text text-uppercase text-center pb-3">
					{t("_service_centers")}
				</h3>

				<div className="row mt-3">
					<div className="col-12 b-radius b-shadow border-0 overflow-hidden p-0">
						<YMaps>
							<Map
								width="100%"
								height="550px"
								modules={["templateLayoutFactory", "layout.ImageWithContent"]}
								state={{
									center: [`${map.centerLatitude}`, `${map.centerLongitute}`],
									zoom: `${map.zoom}`,
									type: "yandex#map",
									controls: [],
								}}
								instanceRef={ref => {
									if (ref) {
										ref.events.add("click", e => {
											ref.balloon.close();
										});
									}
								}}
							>
								{locations}
								<GeolocationControl options={{float: "left"}} />
								<ZoomControl />
								<FullscreenControl />
							</Map>
						</YMaps>
					</div>
					<div className="col-12 mt-3">
						<div className="row justify-content-center">{centerslist}</div>
					</div>
				</div>
			</div>
		</div>
	);
}
