import React, {useState, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {BASE_URL, uidURL, signinURL, signinConfirmURL} from "../../static/api";
import "react-phone-number-input/style.css";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input/mobile";
import InputMask from "react-input-mask";
import small_logo from "../../assets/images/logo-small.jpg";
import {alt_main} from "../../static/Static";
import MetaTags from "../ui-elements/MetaTags";

import {
	FiUser,
	FiPhone,
	FiUserCheck,
	FiArrowRight,
	FiArrowLeft,
	FiPhoneIncoming,
} from "react-icons/fi";

export default function Login(props) {
	const {t} = useTranslation();
	const intervalRef = useRef();
	const inputRef = useRef(null);
	const [userIdForm, setuserIdForm] = useState(true);
	const [userNumberForm, setuserNumberForm] = useState(false);
	const [userCodeForm, setuserCodeForm] = useState(false);
	const [userID, setuserID] = useState("");
	const [userMobile, setuserMobile] = useState("");
	const [userNumber, setuserNumber] = useState("");
	const [userConfiramtion, setuserConfiramtion] = useState("*");
	const [errorCode, seterrorCode] = useState("");
	const [mobButton, setMobButton] = useState(false);
	const [progress, setProgress] = useState(0);
	const [back, setBack] = useState(null);

	function SubmitUserID(e) {
		e.preventDefault();
		if (userID) {
			axios({
				method: "POST",
				url: BASE_URL + uidURL,
				data: {uid: userID},
			})
				.then(res => {
					setuserIdForm(false);
					setuserNumberForm(true);
					setProgress(50);
					setBack(true);
					seterrorCode("");
					setuserMobile(res.data.mobile);
				})
				.catch(error => {
					setuserIdForm(true);
					setuserNumberForm(false);
					setProgress(0);
					setBack(null);
					seterrorCode(error.response.data.error);
				});
		}
	}

	// useEffect(() => {
	//   if(){
	//     setMobButton(true)
	//   } else{
	//     setMobButton(false)
	//   }
	// }, [userNumber])

	function SubmitMobileNumber(e) {
		e.preventDefault();
		seterrorCode("");
		if (isValidPhoneNumber(userNumber)) {
			axios({
				method: "POST",
				url: BASE_URL + signinURL,
				data: {uid: userID, mobile: userNumber},
			})
				.then(res => {
					if (res.status === 200) {
						setuserCodeForm(true);
						setuserNumberForm(false);
						setProgress(100);
						seterrorCode("");
						setError_timer(60);
					}
				})
				.catch(error => {
					if (error.response) {
						setuserNumberForm(true);
						setProgress(50);
					}
				});
		}
	}

	useEffect(() => {
		if (userConfiramtion.length == 4) {
			SubmitCode();
		}
	}, [userConfiramtion]);

	function SubmitCode(e) {
		seterrorCode("");
		if (isValidPhoneNumber(userNumber)) {
			axios({
				method: "POST",
				url: BASE_URL + signinConfirmURL,
				data: {uid: userID, mobile: userNumber, code: userConfiramtion},
			})
				.then(res => {
					if (res.status === 200) {
						localStorage.setItem("token", res.data.token);
						window.location.replace("/user_account/dashboard");
						seterrorCode("");
					}
				})
				.catch(error => {
					seterrorCode(error.response.data.error_code);
				});
		}
	}

	const [error_timer, setError_timer] = useState(0);

	useEffect(() => {
		intervalRef.current = setInterval(() => {
			setError_timer(t => t - 1);
		}, 1000);
		return () => clearInterval(intervalRef.current);
	}, [error_timer]);

	useEffect(() => {
		if (error_timer <= 0) {
			clearInterval(intervalRef.current);
		}
	}, [error_timer]);

	return (
		<>
			<MetaTags title={"Անձնական էջ"} />

			<div className="col-sm-12 col-xs-12 p-0">
				<div className="container pt-5">
					<div className="row align-items-center">
						<div className="col-xl-5 col-lg-6 col-md-7 col-sm-11 mx-auto pt-5 pb-2">
							<div className="cw-100 card b-radius b-shadow border-0 p-5">
								<div className="col-xl-10 col-lg-11 col-11 mx-auto">
									<div className="text-center my-4">
										<img style={{width: "100px"}} src={small_logo} alt={alt_main} />
										<div className="line-inside">
											<span>{t("nav_userlink")}</span>
										</div>
									</div>

									<div className="col-xl-10 col-lg-10 col-11 mx-auto p-0 my-5 position-relative">
										<ul className="d-flex justify-content-between align-items-center">
											<span className={progress >= 0 ? "form-icon active" : "form-icon"}>
												<FiUser></FiUser>
											</span>
											<span className={progress >= 50 ? "form-icon active" : "form-icon"}>
												<FiPhone></FiPhone>
											</span>
											<span className={progress >= 100 ? "form-icon active" : "form-icon"}>
												<FiUserCheck></FiUserCheck>
											</span>
										</ul>
										<div className="progress">
											<div
												className="progress-bar bg-success"
												role="progressbar"
												aria-valuenow={progress}
												aria-valuemin="0"
												aria-valuemax="100"
												style={{width: progress + "%"}}
											></div>
										</div>
									</div>

									{userIdForm && (
										<>
											<form onSubmit={e => SubmitUserID(e)}>
												<div className="text-center my-4">
													{t("_user_page_login_help_text_1")}
													{errorCode ? (
														<div className="text-danger w-100">
															{t("_user_page_login_help_text_error_1")}
														</div>
													) : null}
												</div>

												<div className="form-floating my-5">
													<input
														type="tel"
														className="form-control b-radius b-shadow mt-2 text-muted"
														id="userID"
														placeholder={t("_user_page_login_user_id")}
														autoFocus
														value={userID}
														onChange={e => {
															setuserID(e.target.value.replace(/\D/, ""));
															seterrorCode("");
														}}
													/>
													<label htmlFor="userID">{t("_user_page_login_user_id")}</label>
												</div>

												<div className="col-sm-12 mx-auto mt-4 mb-2 text-center">
													<button
														type="button"
														onClick={e => SubmitUserID(e)}
														disabled={userID ? null : true}
														className="net-btn green-bg green-shadow py-3 px-4 m-2"
													>
														{t("_user_page_login_enter")} <FiArrowRight className="ms-2" />
													</button>
												</div>
											</form>
										</>
									)}

									{userNumberForm && (
										<>
											<form onSubmit={e => SubmitMobileNumber(e)}>
												<div className="text-center my-4">
													{t("_user_page_login_help_text_2")}{" "}
													<span className="text-italic text-success"> {userMobile} </span>,{" "}
													{t("_user_page_login_help_text_2_1")}
													{errorCode ? (
														<>
															<br />
															<span className="text-danger w-100">
																{t("_user_page_login_help_text_error_2")}
															</span>
														</>
													) : null}
												</div>
												<div className="form-floating my-5">
													<PhoneInput
														required
														autoFocus
														international
														displayInitialValueAsLocalNumber
														placeholder={userMobile}
														className="form-control b-radius pl-4"
														ref={inputRef}
														value={userNumber}
														onChange={setuserNumber}
													/>
													<label htmlFor="phone">{t("_user_page_login_phone")}</label>
												</div>

												<div className="col-sm-12 mx-auto mt-4 mb-2 text-center">
													<button
														type="button"
														onClick={e => {
															setuserIdForm(true);
															setuserNumberForm(false);
															setuserID("");
															setProgress(0);
															seterrorCode("");
															setuserNumber("");
														}}
														className="net-btn orange-bg orange-shadow py-3 px-4 m-1"
													>
														<FiArrowLeft className="me-2" /> {t("_user_page_login_go_back")}{" "}
													</button>
													{userNumber && isValidPhoneNumber(userNumber) ? (
														<button
															type="submit"
															onSubmit={userNumber}
															disabled={false}
															className="net-btn green-bg green-shadow py-3 px-4 m-1"
														>
															{t("_user_page_login_enter")} <FiArrowRight className="ms-2" />
														</button>
													) : (
														<button
															type="submit"
															onSubmit={
																userNumber && isValidPhoneNumber(userNumber)
																	? {SubmitMobileNumber}
																	: null
															}
															disabled={userNumber && isValidPhoneNumber(userNumber) ? false : true}
															className="net-btn green-bg green-shadow py-3 px-4 m-2"
														>
															{t("_user_page_login_enter")} <FiArrowRight className="ms-2" />
														</button>
													)}
												</div>
											</form>
										</>
									)}

									{userCodeForm && (
										<>
											<form
												onSubmit={e => {
													e.preventDefault();
													SubmitCode(e);
												}}
											>
												<div className="text-center mb-4">
													{t("_request_text_line_1")} 060XX<span className="text-danger">XXXX</span>{" "}
													{t("_request_text_line_2")}
													<div className="small mt-2 col-12">
														{error_timer > 0 ? (
															<>
																{t("_request_error_text")} {error_timer}{" "}
																{t("_request_error_seconds")}'{" "}
															</>
														) : (
															<button
																onClick={e => {
																	SubmitMobileNumber(e);
																}}
																className="btn green-text mt-2 p-0 m-0"
															>
																<FiPhoneIncoming className="me-2" /> {t("_request_repeat")}{" "}
															</button>
														)}
													</div>
													{errorCode ? (
														<>
															<br />
															<span className="text-danger">{t("_request_error")}</span>
														</>
													) : null}
												</div>
												<div className="form-floating mb-5 mt-0">
													<InputMask
														type="tel"
														required
														autoFocus
														className="form-control text-center b-radius b-shadow mt-2 text-muted fw-bold ls-30 mb-3"
														mask="9999"
														placeholder="_ _ _ _"
														value={userConfiramtion}
														onChange={e =>
															setuserConfiramtion(
																e.target.value.replace(/[`~!@#$%^&*()_|\-=?;:'' '",.<>\\/s]/gi, "")
															)
														}
													/>
												</div>

												<div className="col-sm-12 mx-auto mt-4 mb-2 text-center">
													<button
														type="button"
														onClick={e => {
															setuserNumberForm(true);
															setuserCodeForm(false);
															setProgress(50);
															seterrorCode("");
															setuserNumber("");
															setuserConfiramtion("");
														}}
														className="net-btn orange-bg orange-shadow py-3 px-4 m-2"
													>
														<FiArrowLeft className="me-2" /> {t("_user_page_login_go_back")}{" "}
													</button>

													<button
														type="submit"
														onSubmit={e =>
															SubmitCode(e) && userConfiramtion ? SubmitCode(e) : null
														}
														disabled={userConfiramtion.length > 3 ? false : true}
														className="net-btn green-bg green-shadow py-3 px-4 m-2"
													>
														{t("_user_page_login_enter")} <FiArrowRight className="ms-2" />
													</button>
												</div>
											</form>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
