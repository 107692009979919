import React from "react";
import {useTranslation} from "react-i18next";
import {FiArrowRight} from "react-icons/fi";
import {Link} from "react-router-dom";
import hy_lg_3x from "../../../assets/images/slider/hy_lg_3x.jpg";
import en_lg_3x from "../../../assets/images/slider/en_lg_3x.jpg";
import ru_lg_3x from "../../../assets/images/slider/ru_lg_3x.jpg";
import hy_sm_3x from "../../../assets/images/slider/hy_sm_3x.jpg";
import ru_sm_3x from "../../../assets/images/slider/ru_sm_3x.jpg";
import en_sm_3x from "../../../assets/images/slider/en_sm_3x.jpg";

export default function Index3X() {
	const lng = localStorage.getItem("i18nextLng");
	const {t} = useTranslation();
	return (
		<div className="col-xs-12 p-0 position-relative">
			<div className="container py-4 p-0">
				<img className="hidden-sm hidden-xs" alt={"Arpinet LLC"} src={lng !== "en-US" ? (lng == "ru-RU" ? ru_lg_3x : hy_lg_3x) : en_lg_3x} />
				<img className="hidden-lg hidden-md" alt={"Arpinet LLC"} src={lng !== "en-US" ? (lng == "ru-RU" ? ru_sm_3x : hy_sm_3x) : en_sm_3x} />
				<div className="py-3 position-absolute text-center  " style={{bottom: "50px", left: "35%"}}>
					<Link className="net-btn orange-bg orange-shadow py-3 px-4 m-2" to="/promotions/3x">
						{t("_arpicam_more")} <FiArrowRight className="ms-2" />
					</Link>
				</div>
			</div>
		</div>
	);
}
